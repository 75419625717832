// source: account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var api_pb = require('./api_pb.js');
goog.object.extend(proto, api_pb);
var auth_pb = require('./auth_pb.js');
goog.object.extend(proto, auth_pb);
goog.exportSymbol('proto.org.couchers.api.account.ActiveSession', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ChangeEmailV2Req', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ChangePasswordV2Req', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ChangePhoneReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.DeleteAccountReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.FillContributorFormReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.GetAccountInfoRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.GetContributorFormInfoRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.InitiateStrongVerificationRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ListActiveSessionsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ListActiveSessionsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ListModNotesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.account.LogOutOtherSessionsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.LogOutSessionReq', null, global);
goog.exportSymbol('proto.org.couchers.api.account.ModNote', null, global);
goog.exportSymbol('proto.org.couchers.api.account.StrongVerificationAttemptStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.account.VerifyPhoneReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.GetAccountInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.GetAccountInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.GetAccountInfoRes.displayName = 'proto.org.couchers.api.account.GetAccountInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ChangePasswordV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ChangePasswordV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ChangePasswordV2Req.displayName = 'proto.org.couchers.api.account.ChangePasswordV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ChangeEmailV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ChangeEmailV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ChangeEmailV2Req.displayName = 'proto.org.couchers.api.account.ChangeEmailV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.GetContributorFormInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.GetContributorFormInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.GetContributorFormInfoRes.displayName = 'proto.org.couchers.api.account.GetContributorFormInfoRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.FillContributorFormReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.FillContributorFormReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.FillContributorFormReq.displayName = 'proto.org.couchers.api.account.FillContributorFormReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ChangePhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ChangePhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ChangePhoneReq.displayName = 'proto.org.couchers.api.account.ChangePhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.VerifyPhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.VerifyPhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.VerifyPhoneReq.displayName = 'proto.org.couchers.api.account.VerifyPhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.InitiateStrongVerificationRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.InitiateStrongVerificationRes.displayName = 'proto.org.couchers.api.account.InitiateStrongVerificationRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.displayName = 'proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.displayName = 'proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.DeleteAccountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.DeleteAccountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.DeleteAccountReq.displayName = 'proto.org.couchers.api.account.DeleteAccountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ModNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ModNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ModNote.displayName = 'proto.org.couchers.api.account.ModNote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ListModNotesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.account.ListModNotesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.account.ListModNotesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ListModNotesRes.displayName = 'proto.org.couchers.api.account.ListModNotesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ListActiveSessionsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ListActiveSessionsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ListActiveSessionsReq.displayName = 'proto.org.couchers.api.account.ListActiveSessionsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ActiveSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.ActiveSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ActiveSession.displayName = 'proto.org.couchers.api.account.ActiveSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.ListActiveSessionsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.account.ListActiveSessionsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.account.ListActiveSessionsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.ListActiveSessionsRes.displayName = 'proto.org.couchers.api.account.ListActiveSessionsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.LogOutSessionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.LogOutSessionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.LogOutSessionReq.displayName = 'proto.org.couchers.api.account.LogOutSessionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.account.LogOutOtherSessionsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.account.LogOutOtherSessionsReq.displayName = 'proto.org.couchers.api.account.LogOutOtherSessionsReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.GetAccountInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.GetAccountInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetAccountInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 7, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileComplete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phoneVerified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hasDonated: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasStrongVerification: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    birthdateVerificationStatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
    genderVerificationStatus: jspb.Message.getFieldWithDefault(msg, 12, 0),
    doNotEmail: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isSuperuser: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes}
 */
proto.org.couchers.api.account.GetAccountInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.GetAccountInfoRes;
  return proto.org.couchers.api.account.GetAccountInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.GetAccountInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes}
 */
proto.org.couchers.api.account.GetAccountInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProfileComplete(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneVerified(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDonated(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStrongVerification(value);
      break;
    case 11:
      var value = /** @type {!proto.org.couchers.api.core.BirthdateVerificationStatus} */ (reader.readEnum());
      msg.setBirthdateVerificationStatus(value);
      break;
    case 12:
      var value = /** @type {!proto.org.couchers.api.core.GenderVerificationStatus} */ (reader.readEnum());
      msg.setGenderVerificationStatus(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotEmail(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuperuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.GetAccountInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.GetAccountInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetAccountInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileComplete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhoneVerified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHasDonated();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasStrongVerification();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getBirthdateVerificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getGenderVerificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getDoNotEmail();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsSuperuser();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string username = 7;
 * @return {string}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool profile_complete = 4;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getProfileComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setProfileComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool phone_verified = 9;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getPhoneVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setPhoneVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string timezone = 6;
 * @return {string}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool has_donated = 14;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getHasDonated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setHasDonated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool has_strong_verification = 10;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getHasStrongVerification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setHasStrongVerification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional org.couchers.api.core.BirthdateVerificationStatus birthdate_verification_status = 11;
 * @return {!proto.org.couchers.api.core.BirthdateVerificationStatus}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getBirthdateVerificationStatus = function() {
  return /** @type {!proto.org.couchers.api.core.BirthdateVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.org.couchers.api.core.BirthdateVerificationStatus} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setBirthdateVerificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional org.couchers.api.core.GenderVerificationStatus gender_verification_status = 12;
 * @return {!proto.org.couchers.api.core.GenderVerificationStatus}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getGenderVerificationStatus = function() {
  return /** @type {!proto.org.couchers.api.core.GenderVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.org.couchers.api.core.GenderVerificationStatus} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setGenderVerificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool do_not_email = 13;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getDoNotEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setDoNotEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_superuser = 15;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.getIsSuperuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetAccountInfoRes} returns this
 */
proto.org.couchers.api.account.GetAccountInfoRes.prototype.setIsSuperuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ChangePasswordV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ChangePasswordV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangePasswordV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ChangePasswordV2Req}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ChangePasswordV2Req;
  return proto.org.couchers.api.account.ChangePasswordV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ChangePasswordV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ChangePasswordV2Req}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ChangePasswordV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ChangePasswordV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangePasswordV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_password = 1;
 * @return {string}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ChangePasswordV2Req} returns this
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ChangePasswordV2Req} returns this
 */
proto.org.couchers.api.account.ChangePasswordV2Req.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ChangeEmailV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ChangeEmailV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangeEmailV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ChangeEmailV2Req}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ChangeEmailV2Req;
  return proto.org.couchers.api.account.ChangeEmailV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ChangeEmailV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ChangeEmailV2Req}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ChangeEmailV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ChangeEmailV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangeEmailV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ChangeEmailV2Req} returns this
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_email = 2;
 * @return {string}
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.getNewEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ChangeEmailV2Req} returns this
 */
proto.org.couchers.api.account.ChangeEmailV2Req.prototype.setNewEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.GetContributorFormInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.GetContributorFormInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    filledContributorForm: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.GetContributorFormInfoRes}
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.GetContributorFormInfoRes;
  return proto.org.couchers.api.account.GetContributorFormInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.GetContributorFormInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.GetContributorFormInfoRes}
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilledContributorForm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.GetContributorFormInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.GetContributorFormInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilledContributorForm();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool filled_contributor_form = 1;
 * @return {boolean}
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.prototype.getFilledContributorForm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.GetContributorFormInfoRes} returns this
 */
proto.org.couchers.api.account.GetContributorFormInfoRes.prototype.setFilledContributorForm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.FillContributorFormReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.FillContributorFormReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.FillContributorFormReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.FillContributorFormReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    contributorForm: (f = msg.getContributorForm()) && auth_pb.ContributorForm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.FillContributorFormReq}
 */
proto.org.couchers.api.account.FillContributorFormReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.FillContributorFormReq;
  return proto.org.couchers.api.account.FillContributorFormReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.FillContributorFormReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.FillContributorFormReq}
 */
proto.org.couchers.api.account.FillContributorFormReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new auth_pb.ContributorForm;
      reader.readMessage(value,auth_pb.ContributorForm.deserializeBinaryFromReader);
      msg.setContributorForm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.FillContributorFormReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.FillContributorFormReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.FillContributorFormReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.FillContributorFormReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContributorForm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      auth_pb.ContributorForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional org.couchers.auth.ContributorForm contributor_form = 1;
 * @return {?proto.org.couchers.auth.ContributorForm}
 */
proto.org.couchers.api.account.FillContributorFormReq.prototype.getContributorForm = function() {
  return /** @type{?proto.org.couchers.auth.ContributorForm} */ (
    jspb.Message.getWrapperField(this, auth_pb.ContributorForm, 1));
};


/**
 * @param {?proto.org.couchers.auth.ContributorForm|undefined} value
 * @return {!proto.org.couchers.api.account.FillContributorFormReq} returns this
*/
proto.org.couchers.api.account.FillContributorFormReq.prototype.setContributorForm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.FillContributorFormReq} returns this
 */
proto.org.couchers.api.account.FillContributorFormReq.prototype.clearContributorForm = function() {
  return this.setContributorForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.FillContributorFormReq.prototype.hasContributorForm = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ChangePhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ChangePhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ChangePhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangePhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ChangePhoneReq}
 */
proto.org.couchers.api.account.ChangePhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ChangePhoneReq;
  return proto.org.couchers.api.account.ChangePhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ChangePhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ChangePhoneReq}
 */
proto.org.couchers.api.account.ChangePhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ChangePhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ChangePhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ChangePhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ChangePhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.org.couchers.api.account.ChangePhoneReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ChangePhoneReq} returns this
 */
proto.org.couchers.api.account.ChangePhoneReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.VerifyPhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.VerifyPhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.VerifyPhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.VerifyPhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.VerifyPhoneReq}
 */
proto.org.couchers.api.account.VerifyPhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.VerifyPhoneReq;
  return proto.org.couchers.api.account.VerifyPhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.VerifyPhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.VerifyPhoneReq}
 */
proto.org.couchers.api.account.VerifyPhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.VerifyPhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.VerifyPhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.VerifyPhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.VerifyPhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.org.couchers.api.account.VerifyPhoneReq.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.VerifyPhoneReq} returns this
 */
proto.org.couchers.api.account.VerifyPhoneReq.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.InitiateStrongVerificationRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.InitiateStrongVerificationRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationAttemptToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.InitiateStrongVerificationRes}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.InitiateStrongVerificationRes;
  return proto.org.couchers.api.account.InitiateStrongVerificationRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.InitiateStrongVerificationRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.InitiateStrongVerificationRes}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationAttemptToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.InitiateStrongVerificationRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.InitiateStrongVerificationRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationAttemptToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string verification_attempt_token = 1;
 * @return {string}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.getVerificationAttemptToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.InitiateStrongVerificationRes} returns this
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.setVerificationAttemptToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_url = 3;
 * @return {string}
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.InitiateStrongVerificationRes} returns this
 */
proto.org.couchers.api.account.InitiateStrongVerificationRes.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    verificationAttemptToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq;
  return proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationAttemptToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerificationAttemptToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string verification_attempt_token = 1;
 * @return {string}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.prototype.getVerificationAttemptToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq} returns this
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusReq.prototype.setVerificationAttemptToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes;
  return proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.org.couchers.api.account.StrongVerificationAttemptStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional StrongVerificationAttemptStatus status = 1;
 * @return {!proto.org.couchers.api.account.StrongVerificationAttemptStatus}
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.prototype.getStatus = function() {
  return /** @type {!proto.org.couchers.api.account.StrongVerificationAttemptStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.org.couchers.api.account.StrongVerificationAttemptStatus} value
 * @return {!proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes} returns this
 */
proto.org.couchers.api.account.GetStrongVerificationAttemptStatusRes.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.DeleteAccountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.DeleteAccountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.DeleteAccountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirm: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.DeleteAccountReq}
 */
proto.org.couchers.api.account.DeleteAccountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.DeleteAccountReq;
  return proto.org.couchers.api.account.DeleteAccountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.DeleteAccountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.DeleteAccountReq}
 */
proto.org.couchers.api.account.DeleteAccountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirm(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.DeleteAccountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.DeleteAccountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.DeleteAccountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirm();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool confirm = 1;
 * @return {boolean}
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.getConfirm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.DeleteAccountReq} returns this
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.setConfirm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.DeleteAccountReq} returns this
 */
proto.org.couchers.api.account.DeleteAccountReq.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ModNote.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ModNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ModNote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ModNote.toObject = function(includeInstance, msg) {
  var f, obj = {
    noteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    noteContent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    acknowledged: (f = msg.getAcknowledged()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ModNote}
 */
proto.org.couchers.api.account.ModNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ModNote;
  return proto.org.couchers.api.account.ModNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ModNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ModNote}
 */
proto.org.couchers.api.account.ModNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNoteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoteContent(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAcknowledged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ModNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ModNote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ModNote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ModNote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoteId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getNoteContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAcknowledged();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 note_id = 1;
 * @return {number}
 */
proto.org.couchers.api.account.ModNote.prototype.getNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.account.ModNote} returns this
 */
proto.org.couchers.api.account.ModNote.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string note_content = 2;
 * @return {string}
 */
proto.org.couchers.api.account.ModNote.prototype.getNoteContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ModNote} returns this
 */
proto.org.couchers.api.account.ModNote.prototype.setNoteContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.ModNote.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.ModNote} returns this
*/
proto.org.couchers.api.account.ModNote.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.ModNote} returns this
 */
proto.org.couchers.api.account.ModNote.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.ModNote.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp acknowledged = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.ModNote.prototype.getAcknowledged = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.ModNote} returns this
*/
proto.org.couchers.api.account.ModNote.prototype.setAcknowledged = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.ModNote} returns this
 */
proto.org.couchers.api.account.ModNote.prototype.clearAcknowledged = function() {
  return this.setAcknowledged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.ModNote.prototype.hasAcknowledged = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.account.ListModNotesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ListModNotesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ListModNotesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ListModNotesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListModNotesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    modNotesList: jspb.Message.toObjectList(msg.getModNotesList(),
    proto.org.couchers.api.account.ModNote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ListModNotesRes}
 */
proto.org.couchers.api.account.ListModNotesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ListModNotesRes;
  return proto.org.couchers.api.account.ListModNotesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ListModNotesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ListModNotesRes}
 */
proto.org.couchers.api.account.ListModNotesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.account.ModNote;
      reader.readMessage(value,proto.org.couchers.api.account.ModNote.deserializeBinaryFromReader);
      msg.addModNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ListModNotesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ListModNotesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ListModNotesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListModNotesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.account.ModNote.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModNote mod_notes = 1;
 * @return {!Array<!proto.org.couchers.api.account.ModNote>}
 */
proto.org.couchers.api.account.ListModNotesRes.prototype.getModNotesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.account.ModNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.account.ModNote, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.account.ModNote>} value
 * @return {!proto.org.couchers.api.account.ListModNotesRes} returns this
*/
proto.org.couchers.api.account.ListModNotesRes.prototype.setModNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.account.ModNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.account.ModNote}
 */
proto.org.couchers.api.account.ListModNotesRes.prototype.addModNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.account.ModNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.account.ListModNotesRes} returns this
 */
proto.org.couchers.api.account.ListModNotesRes.prototype.clearModNotesList = function() {
  return this.setModNotesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ListActiveSessionsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ListActiveSessionsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListActiveSessionsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ListActiveSessionsReq}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ListActiveSessionsReq;
  return proto.org.couchers.api.account.ListActiveSessionsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ListActiveSessionsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ListActiveSessionsReq}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ListActiveSessionsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ListActiveSessionsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListActiveSessionsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.account.ListActiveSessionsReq} returns this
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ListActiveSessionsReq} returns this
 */
proto.org.couchers.api.account.ListActiveSessionsReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ActiveSession.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ActiveSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ActiveSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ActiveSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiry: (f = msg.getExpiry()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSeen: (f = msg.getLastSeen()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    operatingSystem: jspb.Message.getFieldWithDefault(msg, 4, ""),
    browser: jspb.Message.getFieldWithDefault(msg, 5, ""),
    device: jspb.Message.getFieldWithDefault(msg, 6, ""),
    approximateLocation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isCurrentSession: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ActiveSession}
 */
proto.org.couchers.api.account.ActiveSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ActiveSession;
  return proto.org.couchers.api.account.ActiveSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ActiveSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ActiveSession}
 */
proto.org.couchers.api.account.ActiveSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiry(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeen(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatingSystem(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrowser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setApproximateLocation(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrentSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ActiveSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ActiveSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ActiveSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ActiveSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSeen();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOperatingSystem();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBrowser();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDevice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getApproximateLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsCurrentSession();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
*/
proto.org.couchers.api.account.ActiveSession.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.ActiveSession.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp expiry = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getExpiry = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
*/
proto.org.couchers.api.account.ActiveSession.prototype.setExpiry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.clearExpiry = function() {
  return this.setExpiry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.ActiveSession.prototype.hasExpiry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_seen = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getLastSeen = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
*/
proto.org.couchers.api.account.ActiveSession.prototype.setLastSeen = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.clearLastSeen = function() {
  return this.setLastSeen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.ActiveSession.prototype.hasLastSeen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string operating_system = 4;
 * @return {string}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getOperatingSystem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.setOperatingSystem = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string browser = 5;
 * @return {string}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getBrowser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.setBrowser = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string device = 6;
 * @return {string}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.setDevice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string approximate_location = 7;
 * @return {string}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getApproximateLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.setApproximateLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_current_session = 8;
 * @return {boolean}
 */
proto.org.couchers.api.account.ActiveSession.prototype.getIsCurrentSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.ActiveSession} returns this
 */
proto.org.couchers.api.account.ActiveSession.prototype.setIsCurrentSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.account.ListActiveSessionsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.ListActiveSessionsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.ListActiveSessionsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListActiveSessionsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeSessionsList: jspb.Message.toObjectList(msg.getActiveSessionsList(),
    proto.org.couchers.api.account.ActiveSession.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.ListActiveSessionsRes}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.ListActiveSessionsRes;
  return proto.org.couchers.api.account.ListActiveSessionsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.ListActiveSessionsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.ListActiveSessionsRes}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.account.ActiveSession;
      reader.readMessage(value,proto.org.couchers.api.account.ActiveSession.deserializeBinaryFromReader);
      msg.addActiveSessions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.ListActiveSessionsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.ListActiveSessionsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.ListActiveSessionsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.account.ActiveSession.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ActiveSession active_sessions = 1;
 * @return {!Array<!proto.org.couchers.api.account.ActiveSession>}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.getActiveSessionsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.account.ActiveSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.account.ActiveSession, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.account.ActiveSession>} value
 * @return {!proto.org.couchers.api.account.ListActiveSessionsRes} returns this
*/
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.setActiveSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.account.ActiveSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.account.ActiveSession}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.addActiveSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.account.ActiveSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.account.ListActiveSessionsRes} returns this
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.clearActiveSessionsList = function() {
  return this.setActiveSessionsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.account.ListActiveSessionsRes} returns this
 */
proto.org.couchers.api.account.ListActiveSessionsRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.LogOutSessionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.LogOutSessionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.LogOutSessionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.LogOutSessionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.LogOutSessionReq}
 */
proto.org.couchers.api.account.LogOutSessionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.LogOutSessionReq;
  return proto.org.couchers.api.account.LogOutSessionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.LogOutSessionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.LogOutSessionReq}
 */
proto.org.couchers.api.account.LogOutSessionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.LogOutSessionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.LogOutSessionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.LogOutSessionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.LogOutSessionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.account.LogOutSessionReq.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.account.LogOutSessionReq} returns this
*/
proto.org.couchers.api.account.LogOutSessionReq.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.account.LogOutSessionReq} returns this
 */
proto.org.couchers.api.account.LogOutSessionReq.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.account.LogOutSessionReq.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.account.LogOutOtherSessionsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.account.LogOutOtherSessionsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    confirm: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.account.LogOutOtherSessionsReq}
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.account.LogOutOtherSessionsReq;
  return proto.org.couchers.api.account.LogOutOtherSessionsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.account.LogOutOtherSessionsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.account.LogOutOtherSessionsReq}
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConfirm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.account.LogOutOtherSessionsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.account.LogOutOtherSessionsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfirm();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool confirm = 1;
 * @return {boolean}
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.prototype.getConfirm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.account.LogOutOtherSessionsReq} returns this
 */
proto.org.couchers.api.account.LogOutOtherSessionsReq.prototype.setConfirm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.org.couchers.api.account.StrongVerificationAttemptStatus = {
  STRONG_VERIFICATION_ATTEMPT_STATUS_UNKNOWN: 0,
  STRONG_VERIFICATION_ATTEMPT_STATUS_SUCCEEDED: 1,
  STRONG_VERIFICATION_ATTEMPT_STATUS_IN_PROGRESS_WAITING_ON_USER_TO_OPEN_APP: 2,
  STRONG_VERIFICATION_ATTEMPT_STATUS_IN_PROGRESS_WAITING_ON_USER_IN_APP: 5,
  STRONG_VERIFICATION_ATTEMPT_STATUS_IN_PROGRESS_WAITING_ON_BACKEND: 3,
  STRONG_VERIFICATION_ATTEMPT_STATUS_FAILED: 4
};

goog.object.extend(exports, proto.org.couchers.api.account);

// source: events.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var threads_pb = require('./threads_pb.js');
goog.object.extend(proto, threads_pb);
goog.exportSymbol('proto.org.couchers.api.events.AttendanceState', null, global);
goog.exportSymbol('proto.org.couchers.api.events.CancelEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.CreateEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.CreateEventReq.ModeCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.Event', null, global);
goog.exportSymbol('proto.org.couchers.api.events.Event.ModeCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.Event.OwnerCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.GetEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.InviteEventOrganizerReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListAllEventsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListAllEventsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventAttendeesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventAttendeesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventOccurrencesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventOccurrencesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventOrganizersReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventOrganizersRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventSubscribersReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListEventSubscribersRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListMyEventsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListMyEventsReq.PaginationCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ListMyEventsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.events.OfflineEventInformation', null, global);
goog.exportSymbol('proto.org.couchers.api.events.OnlineEventInformation', null, global);
goog.exportSymbol('proto.org.couchers.api.events.RemoveEventOrganizerReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.RequestCommunityInviteReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ScheduleEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.ScheduleEventReq.ModeCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.SetEventAttendanceReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.SetEventSubscriptionReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.TransferEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.TransferEventReq.NewOwnerCase', null, global);
goog.exportSymbol('proto.org.couchers.api.events.UpdateEventReq', null, global);
goog.exportSymbol('proto.org.couchers.api.events.UpdateEventReq.ModeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.OnlineEventInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.OnlineEventInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.OnlineEventInformation.displayName = 'proto.org.couchers.api.events.OnlineEventInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.OfflineEventInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.OfflineEventInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.OfflineEventInformation.displayName = 'proto.org.couchers.api.events.OfflineEventInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.Event.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.Event.displayName = 'proto.org.couchers.api.events.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.CreateEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.CreateEventReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.CreateEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.CreateEventReq.displayName = 'proto.org.couchers.api.events.CreateEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ScheduleEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.ScheduleEventReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.ScheduleEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ScheduleEventReq.displayName = 'proto.org.couchers.api.events.ScheduleEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.UpdateEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.UpdateEventReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.UpdateEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.UpdateEventReq.displayName = 'proto.org.couchers.api.events.UpdateEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.GetEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.GetEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.GetEventReq.displayName = 'proto.org.couchers.api.events.GetEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.CancelEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.CancelEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.CancelEventReq.displayName = 'proto.org.couchers.api.events.CancelEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.RequestCommunityInviteReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.RequestCommunityInviteReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.RequestCommunityInviteReq.displayName = 'proto.org.couchers.api.events.RequestCommunityInviteReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventAttendeesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventAttendeesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventAttendeesReq.displayName = 'proto.org.couchers.api.events.ListEventAttendeesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventAttendeesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListEventAttendeesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventAttendeesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventAttendeesRes.displayName = 'proto.org.couchers.api.events.ListEventAttendeesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventSubscribersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventSubscribersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventSubscribersReq.displayName = 'proto.org.couchers.api.events.ListEventSubscribersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventSubscribersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListEventSubscribersRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventSubscribersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventSubscribersRes.displayName = 'proto.org.couchers.api.events.ListEventSubscribersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventOrganizersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventOrganizersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventOrganizersReq.displayName = 'proto.org.couchers.api.events.ListEventOrganizersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventOrganizersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListEventOrganizersRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventOrganizersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventOrganizersRes.displayName = 'proto.org.couchers.api.events.ListEventOrganizersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListMyEventsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.ListMyEventsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListMyEventsReq.displayName = 'proto.org.couchers.api.events.ListMyEventsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListMyEventsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListMyEventsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListMyEventsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListMyEventsRes.displayName = 'proto.org.couchers.api.events.ListMyEventsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListAllEventsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.ListAllEventsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListAllEventsReq.displayName = 'proto.org.couchers.api.events.ListAllEventsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListAllEventsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListAllEventsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListAllEventsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListAllEventsRes.displayName = 'proto.org.couchers.api.events.ListAllEventsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventOccurrencesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventOccurrencesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventOccurrencesReq.displayName = 'proto.org.couchers.api.events.ListEventOccurrencesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.ListEventOccurrencesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.events.ListEventOccurrencesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.events.ListEventOccurrencesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.ListEventOccurrencesRes.displayName = 'proto.org.couchers.api.events.ListEventOccurrencesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.TransferEventReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.events.TransferEventReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.events.TransferEventReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.TransferEventReq.displayName = 'proto.org.couchers.api.events.TransferEventReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.SetEventSubscriptionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.SetEventSubscriptionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.SetEventSubscriptionReq.displayName = 'proto.org.couchers.api.events.SetEventSubscriptionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.SetEventAttendanceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.SetEventAttendanceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.SetEventAttendanceReq.displayName = 'proto.org.couchers.api.events.SetEventAttendanceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.InviteEventOrganizerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.InviteEventOrganizerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.InviteEventOrganizerReq.displayName = 'proto.org.couchers.api.events.InviteEventOrganizerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.events.RemoveEventOrganizerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.events.RemoveEventOrganizerReq.displayName = 'proto.org.couchers.api.events.RemoveEventOrganizerReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.OnlineEventInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.OnlineEventInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.OnlineEventInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.OnlineEventInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.OnlineEventInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.OnlineEventInformation;
  return proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.OnlineEventInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.OnlineEventInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.OnlineEventInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.org.couchers.api.events.OnlineEventInformation.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.OnlineEventInformation} returns this
 */
proto.org.couchers.api.events.OnlineEventInformation.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.OfflineEventInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.OfflineEventInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.OfflineEventInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.OfflineEventInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.OfflineEventInformation;
  return proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.OfflineEventInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.OfflineEventInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.OfflineEventInformation} returns this
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double lat = 2;
 * @return {number}
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.OfflineEventInformation} returns this
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lng = 3;
 * @return {number}
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.OfflineEventInformation} returns this
 */
proto.org.couchers.api.events.OfflineEventInformation.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.Event.oneofGroups_ = [[9,10],[28,29,30]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.Event.ModeCase = {
  MODE_NOT_SET: 0,
  ONLINE_INFORMATION: 9,
  OFFLINE_INFORMATION: 10
};

/**
 * @return {proto.org.couchers.api.events.Event.ModeCase}
 */
proto.org.couchers.api.events.Event.prototype.getModeCase = function() {
  return /** @type {proto.org.couchers.api.events.Event.ModeCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.Event.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.org.couchers.api.events.Event.OwnerCase = {
  OWNER_NOT_SET: 0,
  OWNER_USER_ID: 28,
  OWNER_COMMUNITY_ID: 29,
  OWNER_GROUP_ID: 30
};

/**
 * @return {proto.org.couchers.api.events.Event.OwnerCase}
 */
proto.org.couchers.api.events.Event.prototype.getOwnerCase = function() {
  return /** @type {proto.org.couchers.api.events.Event.OwnerCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.Event.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isNext: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 6, ""),
    content: jspb.Message.getFieldWithDefault(msg, 7, ""),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    onlineInformation: (f = msg.getOnlineInformation()) && proto.org.couchers.api.events.OnlineEventInformation.toObject(includeInstance, f),
    offlineInformation: (f = msg.getOfflineInformation()) && proto.org.couchers.api.events.OfflineEventInformation.toObject(includeInstance, f),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastEdited: (f = msg.getLastEdited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    creatorUserId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 18, ""),
    startTimeDisplay: jspb.Message.getFieldWithDefault(msg, 19, ""),
    endTimeDisplay: jspb.Message.getFieldWithDefault(msg, 20, ""),
    attendanceState: jspb.Message.getFieldWithDefault(msg, 21, 0),
    organizer: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    subscriber: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    goingCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
    maybeCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
    organizerCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
    subscriberCount: jspb.Message.getFieldWithDefault(msg, 27, 0),
    ownerUserId: jspb.Message.getFieldWithDefault(msg, 28, 0),
    ownerCommunityId: jspb.Message.getFieldWithDefault(msg, 29, 0),
    ownerGroupId: jspb.Message.getFieldWithDefault(msg, 30, 0),
    thread: (f = msg.getThread()) && threads_pb.Thread.toObject(includeInstance, f),
    canEdit: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    canModerate: jspb.Message.getBooleanFieldWithDefault(msg, 33, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.events.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.Event;
  return proto.org.couchers.api.events.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.events.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNext(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCancelled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 9:
      var value = new proto.org.couchers.api.events.OnlineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader);
      msg.setOnlineInformation(value);
      break;
    case 10:
      var value = new proto.org.couchers.api.events.OfflineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader);
      msg.setOfflineInformation(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEdited(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatorUserId(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTimeDisplay(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTimeDisplay(value);
      break;
    case 21:
      var value = /** @type {!proto.org.couchers.api.events.AttendanceState} */ (reader.readEnum());
      msg.setAttendanceState(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrganizer(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscriber(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGoingCount(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaybeCount(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrganizerCount(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubscriberCount(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerUserId(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerCommunityId(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerGroupId(value);
      break;
    case 34:
      var value = new threads_pb.Thread;
      reader.readMessage(value,threads_pb.Thread.deserializeBinaryFromReader);
      msg.setThread(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEdit(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanModerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsNext();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsCancelled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOnlineInformation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getOfflineInformation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastEdited();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatorUserId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getStartTimeDisplay();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getEndTimeDisplay();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAttendanceState();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getOrganizer();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getSubscriber();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getGoingCount();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getMaybeCount();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getOrganizerCount();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getSubscriberCount();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getThread();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      threads_pb.Thread.serializeBinaryToWriter
    );
  }
  f = message.getCanEdit();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getCanModerate();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_next = 2;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getIsNext = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setIsNext = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_cancelled = 3;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getIsCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setIsCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_deleted = 4;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string slug = 6;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string content = 7;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string photo_url = 8;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OnlineEventInformation online_information = 9;
 * @return {?proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.Event.prototype.getOnlineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OnlineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OnlineEventInformation, 9));
};


/**
 * @param {?proto.org.couchers.api.events.OnlineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setOnlineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.org.couchers.api.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearOnlineInformation = function() {
  return this.setOnlineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasOnlineInformation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional OfflineEventInformation offline_information = 10;
 * @return {?proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.Event.prototype.getOfflineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OfflineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OfflineEventInformation, 10));
};


/**
 * @param {?proto.org.couchers.api.events.OfflineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setOfflineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.org.couchers.api.events.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearOfflineInformation = function() {
  return this.setOfflineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasOfflineInformation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp created = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.Event.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp last_edited = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.Event.prototype.getLastEdited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setLastEdited = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearLastEdited = function() {
  return this.setLastEdited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasLastEdited = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional int64 creator_user_id = 15;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getCreatorUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setCreatorUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.Event.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.Event.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string timezone = 18;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string start_time_display = 19;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getStartTimeDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setStartTimeDisplay = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string end_time_display = 20;
 * @return {string}
 */
proto.org.couchers.api.events.Event.prototype.getEndTimeDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setEndTimeDisplay = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional AttendanceState attendance_state = 21;
 * @return {!proto.org.couchers.api.events.AttendanceState}
 */
proto.org.couchers.api.events.Event.prototype.getAttendanceState = function() {
  return /** @type {!proto.org.couchers.api.events.AttendanceState} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.org.couchers.api.events.AttendanceState} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setAttendanceState = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional bool organizer = 22;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getOrganizer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setOrganizer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool subscriber = 23;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getSubscriber = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setSubscriber = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional uint32 going_count = 24;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getGoingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setGoingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint32 maybe_count = 25;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getMaybeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setMaybeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint32 organizer_count = 26;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getOrganizerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setOrganizerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint32 subscriber_count = 27;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getSubscriberCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setSubscriberCount = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 owner_user_id = 28;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getOwnerUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setOwnerUserId = function(value) {
  return jspb.Message.setOneofField(this, 28, proto.org.couchers.api.events.Event.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearOwnerUserId = function() {
  return jspb.Message.setOneofField(this, 28, proto.org.couchers.api.events.Event.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasOwnerUserId = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional int64 owner_community_id = 29;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getOwnerCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setOwnerCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 29, proto.org.couchers.api.events.Event.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearOwnerCommunityId = function() {
  return jspb.Message.setOneofField(this, 29, proto.org.couchers.api.events.Event.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasOwnerCommunityId = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional int64 owner_group_id = 30;
 * @return {number}
 */
proto.org.couchers.api.events.Event.prototype.getOwnerGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setOwnerGroupId = function(value) {
  return jspb.Message.setOneofField(this, 30, proto.org.couchers.api.events.Event.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearOwnerGroupId = function() {
  return jspb.Message.setOneofField(this, 30, proto.org.couchers.api.events.Event.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasOwnerGroupId = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional org.couchers.api.threads.Thread thread = 34;
 * @return {?proto.org.couchers.api.threads.Thread}
 */
proto.org.couchers.api.events.Event.prototype.getThread = function() {
  return /** @type{?proto.org.couchers.api.threads.Thread} */ (
    jspb.Message.getWrapperField(this, threads_pb.Thread, 34));
};


/**
 * @param {?proto.org.couchers.api.threads.Thread|undefined} value
 * @return {!proto.org.couchers.api.events.Event} returns this
*/
proto.org.couchers.api.events.Event.prototype.setThread = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.clearThread = function() {
  return this.setThread(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.hasThread = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional bool can_edit = 32;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getCanEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setCanEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool can_moderate = 33;
 * @return {boolean}
 */
proto.org.couchers.api.events.Event.prototype.getCanModerate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.Event} returns this
 */
proto.org.couchers.api.events.Event.prototype.setCanModerate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.CreateEventReq.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.CreateEventReq.ModeCase = {
  MODE_NOT_SET: 0,
  ONLINE_INFORMATION: 4,
  OFFLINE_INFORMATION: 5
};

/**
 * @return {proto.org.couchers.api.events.CreateEventReq.ModeCase}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getModeCase = function() {
  return /** @type {proto.org.couchers.api.events.CreateEventReq.ModeCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.CreateEventReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.CreateEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.CreateEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.CreateEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    photoKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onlineInformation: (f = msg.getOnlineInformation()) && proto.org.couchers.api.events.OnlineEventInformation.toObject(includeInstance, f),
    offlineInformation: (f = msg.getOfflineInformation()) && proto.org.couchers.api.events.OfflineEventInformation.toObject(includeInstance, f),
    parentCommunityId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.CreateEventReq}
 */
proto.org.couchers.api.events.CreateEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.CreateEventReq;
  return proto.org.couchers.api.events.CreateEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.CreateEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.CreateEventReq}
 */
proto.org.couchers.api.events.CreateEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoKey(value);
      break;
    case 4:
      var value = new proto.org.couchers.api.events.OnlineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader);
      msg.setOnlineInformation(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.events.OfflineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader);
      msg.setOfflineInformation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentCommunityId(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.CreateEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.CreateEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.CreateEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhotoKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnlineInformation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getOfflineInformation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getParentCommunityId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string photo_key = 3;
 * @return {string}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getPhotoKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OnlineEventInformation online_information = 4;
 * @return {?proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getOnlineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OnlineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OnlineEventInformation, 4));
};


/**
 * @param {?proto.org.couchers.api.events.OnlineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
*/
proto.org.couchers.api.events.CreateEventReq.prototype.setOnlineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.org.couchers.api.events.CreateEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.clearOnlineInformation = function() {
  return this.setOnlineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.hasOnlineInformation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OfflineEventInformation offline_information = 5;
 * @return {?proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getOfflineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OfflineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OfflineEventInformation, 5));
};


/**
 * @param {?proto.org.couchers.api.events.OfflineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
*/
proto.org.couchers.api.events.CreateEventReq.prototype.setOfflineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.org.couchers.api.events.CreateEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.clearOfflineInformation = function() {
  return this.setOfflineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.hasOfflineInformation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 parent_community_id = 8;
 * @return {number}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getParentCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.setParentCommunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
*/
proto.org.couchers.api.events.CreateEventReq.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
*/
proto.org.couchers.api.events.CreateEventReq.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string timezone = 11;
 * @return {string}
 */
proto.org.couchers.api.events.CreateEventReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.CreateEventReq} returns this
 */
proto.org.couchers.api.events.CreateEventReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.ScheduleEventReq.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.ScheduleEventReq.ModeCase = {
  MODE_NOT_SET: 0,
  ONLINE_INFORMATION: 4,
  OFFLINE_INFORMATION: 5
};

/**
 * @return {proto.org.couchers.api.events.ScheduleEventReq.ModeCase}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getModeCase = function() {
  return /** @type {proto.org.couchers.api.events.ScheduleEventReq.ModeCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.ScheduleEventReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ScheduleEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ScheduleEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ScheduleEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    photoKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onlineInformation: (f = msg.getOnlineInformation()) && proto.org.couchers.api.events.OnlineEventInformation.toObject(includeInstance, f),
    offlineInformation: (f = msg.getOfflineInformation()) && proto.org.couchers.api.events.OfflineEventInformation.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq}
 */
proto.org.couchers.api.events.ScheduleEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ScheduleEventReq;
  return proto.org.couchers.api.events.ScheduleEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ScheduleEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq}
 */
proto.org.couchers.api.events.ScheduleEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoKey(value);
      break;
    case 4:
      var value = new proto.org.couchers.api.events.OnlineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader);
      msg.setOnlineInformation(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.events.OfflineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader);
      msg.setOfflineInformation(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ScheduleEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ScheduleEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ScheduleEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhotoKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnlineInformation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getOfflineInformation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string photo_key = 3;
 * @return {string}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getPhotoKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OnlineEventInformation online_information = 4;
 * @return {?proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getOnlineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OnlineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OnlineEventInformation, 4));
};


/**
 * @param {?proto.org.couchers.api.events.OnlineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
*/
proto.org.couchers.api.events.ScheduleEventReq.prototype.setOnlineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.org.couchers.api.events.ScheduleEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.clearOnlineInformation = function() {
  return this.setOnlineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.hasOnlineInformation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OfflineEventInformation offline_information = 5;
 * @return {?proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getOfflineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OfflineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OfflineEventInformation, 5));
};


/**
 * @param {?proto.org.couchers.api.events.OfflineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
*/
proto.org.couchers.api.events.ScheduleEventReq.prototype.setOfflineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.org.couchers.api.events.ScheduleEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.clearOfflineInformation = function() {
  return this.setOfflineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.hasOfflineInformation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
*/
proto.org.couchers.api.events.ScheduleEventReq.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
*/
proto.org.couchers.api.events.ScheduleEventReq.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string timezone = 10;
 * @return {string}
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ScheduleEventReq} returns this
 */
proto.org.couchers.api.events.ScheduleEventReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.UpdateEventReq.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.UpdateEventReq.ModeCase = {
  MODE_NOT_SET: 0,
  ONLINE_INFORMATION: 6,
  OFFLINE_INFORMATION: 7
};

/**
 * @return {proto.org.couchers.api.events.UpdateEventReq.ModeCase}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getModeCase = function() {
  return /** @type {proto.org.couchers.api.events.UpdateEventReq.ModeCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.UpdateEventReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.UpdateEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.UpdateEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.UpdateEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updateAllFuture: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    content: (f = msg.getContent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    photoKey: (f = msg.getPhotoKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    onlineInformation: (f = msg.getOnlineInformation()) && proto.org.couchers.api.events.OnlineEventInformation.toObject(includeInstance, f),
    offlineInformation: (f = msg.getOfflineInformation()) && proto.org.couchers.api.events.OfflineEventInformation.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timezone: (f = msg.getTimezone()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.UpdateEventReq}
 */
proto.org.couchers.api.events.UpdateEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.UpdateEventReq;
  return proto.org.couchers.api.events.UpdateEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.UpdateEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.UpdateEventReq}
 */
proto.org.couchers.api.events.UpdateEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdateAllFuture(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhotoKey(value);
      break;
    case 6:
      var value = new proto.org.couchers.api.events.OnlineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OnlineEventInformation.deserializeBinaryFromReader);
      msg.setOnlineInformation(value);
      break;
    case 7:
      var value = new proto.org.couchers.api.events.OfflineEventInformation;
      reader.readMessage(value,proto.org.couchers.api.events.OfflineEventInformation.deserializeBinaryFromReader);
      msg.setOfflineInformation(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.UpdateEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.UpdateEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.UpdateEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpdateAllFuture();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhotoKey();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOnlineInformation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.org.couchers.api.events.OnlineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getOfflineInformation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.org.couchers.api.events.OfflineEventInformation.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool update_all_future = 2;
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getUpdateAllFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.setUpdateAllFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue title = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue content = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasContent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue photo_key = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getPhotoKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearPhotoKey = function() {
  return this.setPhotoKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasPhotoKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OnlineEventInformation online_information = 6;
 * @return {?proto.org.couchers.api.events.OnlineEventInformation}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getOnlineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OnlineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OnlineEventInformation, 6));
};


/**
 * @param {?proto.org.couchers.api.events.OnlineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setOnlineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.org.couchers.api.events.UpdateEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearOnlineInformation = function() {
  return this.setOnlineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasOnlineInformation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OfflineEventInformation offline_information = 7;
 * @return {?proto.org.couchers.api.events.OfflineEventInformation}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getOfflineInformation = function() {
  return /** @type{?proto.org.couchers.api.events.OfflineEventInformation} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.events.OfflineEventInformation, 7));
};


/**
 * @param {?proto.org.couchers.api.events.OfflineEventInformation|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setOfflineInformation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.org.couchers.api.events.UpdateEventReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearOfflineInformation = function() {
  return this.setOfflineInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasOfflineInformation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue timezone = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.getTimezone = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
*/
proto.org.couchers.api.events.UpdateEventReq.prototype.setTimezone = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.events.UpdateEventReq} returns this
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.clearTimezone = function() {
  return this.setTimezone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.UpdateEventReq.prototype.hasTimezone = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.GetEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.GetEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.GetEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.GetEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.GetEventReq}
 */
proto.org.couchers.api.events.GetEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.GetEventReq;
  return proto.org.couchers.api.events.GetEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.GetEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.GetEventReq}
 */
proto.org.couchers.api.events.GetEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.GetEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.GetEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.GetEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.GetEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.GetEventReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.GetEventReq} returns this
 */
proto.org.couchers.api.events.GetEventReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.CancelEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.CancelEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.CancelEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.CancelEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.CancelEventReq}
 */
proto.org.couchers.api.events.CancelEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.CancelEventReq;
  return proto.org.couchers.api.events.CancelEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.CancelEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.CancelEventReq}
 */
proto.org.couchers.api.events.CancelEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.CancelEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.CancelEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.CancelEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.CancelEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.CancelEventReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.CancelEventReq} returns this
 */
proto.org.couchers.api.events.CancelEventReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.RequestCommunityInviteReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.RequestCommunityInviteReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.RequestCommunityInviteReq}
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.RequestCommunityInviteReq;
  return proto.org.couchers.api.events.RequestCommunityInviteReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.RequestCommunityInviteReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.RequestCommunityInviteReq}
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.RequestCommunityInviteReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.RequestCommunityInviteReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.RequestCommunityInviteReq} returns this
 */
proto.org.couchers.api.events.RequestCommunityInviteReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventAttendeesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventAttendeesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventAttendeesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventAttendeesReq}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventAttendeesReq;
  return proto.org.couchers.api.events.ListEventAttendeesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventAttendeesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventAttendeesReq}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventAttendeesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventAttendeesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventAttendeesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventAttendeesReq} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventAttendeesReq} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventAttendeesReq} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListEventAttendeesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventAttendeesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventAttendeesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventAttendeesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    attendeeUserIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventAttendeesRes;
  return proto.org.couchers.api.events.ListEventAttendeesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventAttendeesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAttendeeUserIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventAttendeesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventAttendeesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventAttendeesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttendeeUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 attendee_user_ids = 1;
 * @return {!Array<number>}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.getAttendeeUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.setAttendeeUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.addAttendeeUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.clearAttendeeUserIdsList = function() {
  return this.setAttendeeUserIdsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventAttendeesRes} returns this
 */
proto.org.couchers.api.events.ListEventAttendeesRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventSubscribersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventSubscribersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventSubscribersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventSubscribersReq}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventSubscribersReq;
  return proto.org.couchers.api.events.ListEventSubscribersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventSubscribersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventSubscribersReq}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventSubscribersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventSubscribersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventSubscribersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventSubscribersReq} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventSubscribersReq} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventSubscribersReq} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListEventSubscribersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventSubscribersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventSubscribersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventSubscribersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriberUserIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventSubscribersRes;
  return proto.org.couchers.api.events.ListEventSubscribersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventSubscribersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubscriberUserIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventSubscribersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventSubscribersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventSubscribersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriberUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 subscriber_user_ids = 1;
 * @return {!Array<number>}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.getSubscriberUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.setSubscriberUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.addSubscriberUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.clearSubscriberUserIdsList = function() {
  return this.setSubscriberUserIdsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventSubscribersRes} returns this
 */
proto.org.couchers.api.events.ListEventSubscribersRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventOrganizersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventOrganizersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOrganizersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventOrganizersReq}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventOrganizersReq;
  return proto.org.couchers.api.events.ListEventOrganizersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventOrganizersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventOrganizersReq}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventOrganizersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventOrganizersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOrganizersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventOrganizersReq} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventOrganizersReq} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventOrganizersReq} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListEventOrganizersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventOrganizersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventOrganizersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOrganizersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizerUserIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventOrganizersRes;
  return proto.org.couchers.api.events.ListEventOrganizersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventOrganizersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizerUserIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventOrganizersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventOrganizersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOrganizersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizerUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 organizer_user_ids = 1;
 * @return {!Array<number>}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.getOrganizerUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.setOrganizerUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.addOrganizerUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.clearOrganizerUserIdsList = function() {
  return this.setOrganizerUserIdsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventOrganizersRes} returns this
 */
proto.org.couchers.api.events.ListEventOrganizersRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_ = [[6,9]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.ListMyEventsReq.PaginationCase = {
  PAGINATION_NOT_SET: 0,
  PAGE_TOKEN: 6,
  PAGE_NUMBER: 9
};

/**
 * @return {proto.org.couchers.api.events.ListMyEventsReq.PaginationCase}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getPaginationCase = function() {
  return /** @type {proto.org.couchers.api.events.ListMyEventsReq.PaginationCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListMyEventsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListMyEventsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListMyEventsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    past: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    subscribed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    attending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    organizing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    myCommunities: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    includeCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    pageSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListMyEventsReq}
 */
proto.org.couchers.api.events.ListMyEventsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListMyEventsReq;
  return proto.org.couchers.api.events.ListMyEventsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListMyEventsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListMyEventsReq}
 */
proto.org.couchers.api.events.ListMyEventsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPast(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribed(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttending(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrganizing(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyCommunities(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCancelled(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListMyEventsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListMyEventsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListMyEventsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPast();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSubscribed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAttending();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOrganizing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMyCommunities();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIncludeCancelled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional bool past = 1;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool subscribed = 2;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool attending = 3;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getAttending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setAttending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool organizing = 4;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getOrganizing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setOrganizing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool my_communities = 7;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getMyCommunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setMyCommunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool include_cancelled = 8;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getIncludeCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setIncludeCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 page_size = 5;
 * @return {number}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string page_token = 6;
 * @return {string}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setPageToken = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.clearPageToken = function() {
  return jspb.Message.setOneofField(this, 6, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.hasPageToken = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 page_number = 9;
 * @return {number}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.setPageNumber = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.ListMyEventsReq} returns this
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.clearPageNumber = function() {
  return jspb.Message.setOneofField(this, 9, proto.org.couchers.api.events.ListMyEventsReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.ListMyEventsReq.prototype.hasPageNumber = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListMyEventsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListMyEventsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListMyEventsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListMyEventsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.org.couchers.api.events.Event.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalItems: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListMyEventsRes}
 */
proto.org.couchers.api.events.ListMyEventsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListMyEventsRes;
  return proto.org.couchers.api.events.ListMyEventsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListMyEventsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListMyEventsRes}
 */
proto.org.couchers.api.events.ListMyEventsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.events.Event;
      reader.readMessage(value,proto.org.couchers.api.events.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListMyEventsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListMyEventsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListMyEventsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.events.Event.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.org.couchers.api.events.Event>}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.events.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.events.Event, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.events.Event>} value
 * @return {!proto.org.couchers.api.events.ListMyEventsRes} returns this
*/
proto.org.couchers.api.events.ListMyEventsRes.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.events.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.events.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListMyEventsRes} returns this
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListMyEventsRes} returns this
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 total_items = 4;
 * @return {number}
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListMyEventsRes} returns this
 */
proto.org.couchers.api.events.ListMyEventsRes.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListAllEventsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListAllEventsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListAllEventsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    past: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListAllEventsReq}
 */
proto.org.couchers.api.events.ListAllEventsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListAllEventsReq;
  return proto.org.couchers.api.events.ListAllEventsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListAllEventsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListAllEventsReq}
 */
proto.org.couchers.api.events.ListAllEventsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPast(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCancelled(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListAllEventsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListAllEventsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListAllEventsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPast();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeCancelled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool past = 1;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.getPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListAllEventsReq} returns this
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.setPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_cancelled = 4;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.getIncludeCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListAllEventsReq} returns this
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.setIncludeCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListAllEventsReq} returns this
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListAllEventsReq} returns this
 */
proto.org.couchers.api.events.ListAllEventsReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListAllEventsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListAllEventsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListAllEventsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListAllEventsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.org.couchers.api.events.Event.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListAllEventsRes}
 */
proto.org.couchers.api.events.ListAllEventsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListAllEventsRes;
  return proto.org.couchers.api.events.ListAllEventsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListAllEventsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListAllEventsRes}
 */
proto.org.couchers.api.events.ListAllEventsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.events.Event;
      reader.readMessage(value,proto.org.couchers.api.events.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListAllEventsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListAllEventsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListAllEventsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.events.Event.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.org.couchers.api.events.Event>}
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.events.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.events.Event, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.events.Event>} value
 * @return {!proto.org.couchers.api.events.ListAllEventsRes} returns this
*/
proto.org.couchers.api.events.ListAllEventsRes.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.events.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.events.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListAllEventsRes} returns this
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListAllEventsRes} returns this
 */
proto.org.couchers.api.events.ListAllEventsRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventOccurrencesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    past: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pageSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventOccurrencesReq;
  return proto.org.couchers.api.events.ListEventOccurrencesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPast(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCancelled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventOccurrencesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPast();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeCancelled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool past = 2;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.getPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.setPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_cancelled = 5;
 * @return {boolean}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.getIncludeCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.setIncludeCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional uint32 page_size = 3;
 * @return {number}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesReq} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.ListEventOccurrencesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.org.couchers.api.events.Event.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesRes}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.ListEventOccurrencesRes;
  return proto.org.couchers.api.events.ListEventOccurrencesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesRes}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.events.Event;
      reader.readMessage(value,proto.org.couchers.api.events.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.ListEventOccurrencesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.ListEventOccurrencesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.events.Event.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.org.couchers.api.events.Event>}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.events.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.events.Event, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.events.Event>} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesRes} returns this
*/
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.events.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.events.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesRes} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.events.ListEventOccurrencesRes} returns this
 */
proto.org.couchers.api.events.ListEventOccurrencesRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.events.TransferEventReq.oneofGroups_ = [[3,2]];

/**
 * @enum {number}
 */
proto.org.couchers.api.events.TransferEventReq.NewOwnerCase = {
  NEW_OWNER_NOT_SET: 0,
  NEW_OWNER_COMMUNITY_ID: 3,
  NEW_OWNER_GROUP_ID: 2
};

/**
 * @return {proto.org.couchers.api.events.TransferEventReq.NewOwnerCase}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.getNewOwnerCase = function() {
  return /** @type {proto.org.couchers.api.events.TransferEventReq.NewOwnerCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.events.TransferEventReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.TransferEventReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.TransferEventReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.TransferEventReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newOwnerCommunityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    newOwnerGroupId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.TransferEventReq}
 */
proto.org.couchers.api.events.TransferEventReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.TransferEventReq;
  return proto.org.couchers.api.events.TransferEventReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.TransferEventReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.TransferEventReq}
 */
proto.org.couchers.api.events.TransferEventReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewOwnerCommunityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewOwnerGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.TransferEventReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.TransferEventReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.TransferEventReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.TransferEventReq} returns this
 */
proto.org.couchers.api.events.TransferEventReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 new_owner_community_id = 3;
 * @return {number}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.getNewOwnerCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.TransferEventReq} returns this
 */
proto.org.couchers.api.events.TransferEventReq.prototype.setNewOwnerCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.events.TransferEventReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.TransferEventReq} returns this
 */
proto.org.couchers.api.events.TransferEventReq.prototype.clearNewOwnerCommunityId = function() {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.events.TransferEventReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.hasNewOwnerCommunityId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 new_owner_group_id = 2;
 * @return {number}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.getNewOwnerGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.TransferEventReq} returns this
 */
proto.org.couchers.api.events.TransferEventReq.prototype.setNewOwnerGroupId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.org.couchers.api.events.TransferEventReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.events.TransferEventReq} returns this
 */
proto.org.couchers.api.events.TransferEventReq.prototype.clearNewOwnerGroupId = function() {
  return jspb.Message.setOneofField(this, 2, proto.org.couchers.api.events.TransferEventReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.events.TransferEventReq.prototype.hasNewOwnerGroupId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.SetEventSubscriptionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.SetEventSubscriptionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subscribe: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.SetEventSubscriptionReq}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.SetEventSubscriptionReq;
  return proto.org.couchers.api.events.SetEventSubscriptionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.SetEventSubscriptionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.SetEventSubscriptionReq}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.SetEventSubscriptionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.SetEventSubscriptionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSubscribe();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.SetEventSubscriptionReq} returns this
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool subscribe = 2;
 * @return {boolean}
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.getSubscribe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.events.SetEventSubscriptionReq} returns this
 */
proto.org.couchers.api.events.SetEventSubscriptionReq.prototype.setSubscribe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.SetEventAttendanceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.SetEventAttendanceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.SetEventAttendanceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attendanceState: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.SetEventAttendanceReq}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.SetEventAttendanceReq;
  return proto.org.couchers.api.events.SetEventAttendanceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.SetEventAttendanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.SetEventAttendanceReq}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.events.AttendanceState} */ (reader.readEnum());
      msg.setAttendanceState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.SetEventAttendanceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.SetEventAttendanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.SetEventAttendanceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAttendanceState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.SetEventAttendanceReq} returns this
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AttendanceState attendance_state = 2;
 * @return {!proto.org.couchers.api.events.AttendanceState}
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.getAttendanceState = function() {
  return /** @type {!proto.org.couchers.api.events.AttendanceState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.events.AttendanceState} value
 * @return {!proto.org.couchers.api.events.SetEventAttendanceReq} returns this
 */
proto.org.couchers.api.events.SetEventAttendanceReq.prototype.setAttendanceState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.InviteEventOrganizerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.InviteEventOrganizerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.InviteEventOrganizerReq}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.InviteEventOrganizerReq;
  return proto.org.couchers.api.events.InviteEventOrganizerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.InviteEventOrganizerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.InviteEventOrganizerReq}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.InviteEventOrganizerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.InviteEventOrganizerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.InviteEventOrganizerReq} returns this
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.InviteEventOrganizerReq} returns this
 */
proto.org.couchers.api.events.InviteEventOrganizerReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.events.RemoveEventOrganizerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.events.RemoveEventOrganizerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.events.RemoveEventOrganizerReq}
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.events.RemoveEventOrganizerReq;
  return proto.org.couchers.api.events.RemoveEventOrganizerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.events.RemoveEventOrganizerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.events.RemoveEventOrganizerReq}
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.events.RemoveEventOrganizerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.events.RemoveEventOrganizerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.events.RemoveEventOrganizerReq} returns this
 */
proto.org.couchers.api.events.RemoveEventOrganizerReq.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.org.couchers.api.events.AttendanceState = {
  ATTENDANCE_STATE_NOT_GOING: 0,
  ATTENDANCE_STATE_MAYBE: 1,
  ATTENDANCE_STATE_GOING: 2
};

goog.object.extend(exports, proto.org.couchers.api.events);

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function NotificationNewIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
      <svg
        width="37"
        height="37"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 12.4395V10.5H18V12.75C18 12.9489 18.0791 13.1396 18.2197 13.2803L20.25 15.3105V16.5H3.75V15.3105L5.78025 13.2803C5.92091 13.1396 5.99996 12.9489 6 12.75V9.75C5.99791 8.6961 6.27398 7.6603 6.80032 6.74724C7.32665 5.83417 8.08463 5.07618 8.99767 4.5498C9.91072 4.02343 10.9465 3.74733 12.0004 3.74938C13.0543 3.75144 14.089 4.03157 15 4.5615V2.88525C14.2861 2.56914 13.5267 2.36766 12.75 2.28825V0.75H11.25V2.2875C9.40093 2.4757 7.68732 3.34284 6.44053 4.72124C5.19373 6.09964 4.50233 7.89138 4.5 9.75V12.4395L2.46975 14.4697C2.32909 14.6104 2.25004 14.8011 2.25 15V17.25C2.25 17.4489 2.32902 17.6397 2.46967 17.7803C2.61032 17.921 2.80109 18 3 18H8.25V18.75C8.25 19.7446 8.64509 20.6984 9.34835 21.4016C10.0516 22.1049 11.0054 22.5 12 22.5C12.9946 22.5 13.9484 22.1049 14.6517 21.4016C15.3549 20.6984 15.75 19.7446 15.75 18.75V18H21C21.1989 18 21.3897 17.921 21.5303 17.7803C21.671 17.6397 21.75 17.4489 21.75 17.25V15C21.75 14.8011 21.6709 14.6104 21.5303 14.4697L19.5 12.4395ZM14.25 18.75C14.25 19.3467 14.0129 19.919 13.591 20.341C13.169 20.7629 12.5967 21 12 21C11.4033 21 10.831 20.7629 10.409 20.341C9.98705 19.919 9.75 19.3467 9.75 18.75V18H14.25V18.75Z"
          fill="#767676"
        />
        <path
          d="M19.5 9C21.1569 9 22.5 7.65685 22.5 6C22.5 4.34315 21.1569 3 19.5 3C17.8431 3 16.5 4.34315 16.5 6C16.5 7.65685 17.8431 9 19.5 9Z"
          fill="#767676"
        />
      </svg>
    </SvgIcon>
  );
}

// source: requests.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var conversations_pb = require('./conversations_pb.js');
goog.object.extend(proto, conversations_pb);
goog.exportSymbol('proto.org.couchers.api.requests.CreateHostRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.CreateHostRequestRes', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetHostRequestMessagesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetHostRequestMessagesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetHostRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetHostRequestUpdatesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetHostRequestUpdatesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetResponseRateReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetResponseRateRes', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.GetResponseRateRes.ResponseRateCase', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.HostRequest', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.HostRequestUpdate', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ListHostRequestsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ListHostRequestsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.MarkLastSeenHostRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.RespondHostRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRate', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRateAlmostAll', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRateInsufficientData', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRateLow', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRateMost', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.ResponseRateSome', null, global);
goog.exportSymbol('proto.org.couchers.api.requests.SendHostRequestMessageReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.CreateHostRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.CreateHostRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.CreateHostRequestReq.displayName = 'proto.org.couchers.api.requests.CreateHostRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.HostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.HostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.HostRequest.displayName = 'proto.org.couchers.api.requests.HostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetHostRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.GetHostRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetHostRequestReq.displayName = 'proto.org.couchers.api.requests.GetHostRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.CreateHostRequestRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.CreateHostRequestRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.CreateHostRequestRes.displayName = 'proto.org.couchers.api.requests.CreateHostRequestRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.RespondHostRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.RespondHostRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.RespondHostRequestReq.displayName = 'proto.org.couchers.api.requests.RespondHostRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ListHostRequestsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ListHostRequestsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ListHostRequestsReq.displayName = 'proto.org.couchers.api.requests.ListHostRequestsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ListHostRequestsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.requests.ListHostRequestsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.requests.ListHostRequestsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ListHostRequestsRes.displayName = 'proto.org.couchers.api.requests.ListHostRequestsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.GetHostRequestMessagesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetHostRequestMessagesReq.displayName = 'proto.org.couchers.api.requests.GetHostRequestMessagesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.requests.GetHostRequestMessagesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.requests.GetHostRequestMessagesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetHostRequestMessagesRes.displayName = 'proto.org.couchers.api.requests.GetHostRequestMessagesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.SendHostRequestMessageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.SendHostRequestMessageReq.displayName = 'proto.org.couchers.api.requests.SendHostRequestMessageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.GetHostRequestUpdatesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetHostRequestUpdatesReq.displayName = 'proto.org.couchers.api.requests.GetHostRequestUpdatesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.HostRequestUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.HostRequestUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.HostRequestUpdate.displayName = 'proto.org.couchers.api.requests.HostRequestUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.requests.GetHostRequestUpdatesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.requests.GetHostRequestUpdatesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetHostRequestUpdatesRes.displayName = 'proto.org.couchers.api.requests.GetHostRequestUpdatesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.MarkLastSeenHostRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.displayName = 'proto.org.couchers.api.requests.MarkLastSeenHostRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetResponseRateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.GetResponseRateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetResponseRateReq.displayName = 'proto.org.couchers.api.requests.GetResponseRateReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ResponseRateInsufficientData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ResponseRateInsufficientData.displayName = 'proto.org.couchers.api.requests.ResponseRateInsufficientData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ResponseRateLow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ResponseRateLow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ResponseRateLow.displayName = 'proto.org.couchers.api.requests.ResponseRateLow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ResponseRateSome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ResponseRateSome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ResponseRateSome.displayName = 'proto.org.couchers.api.requests.ResponseRateSome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ResponseRateMost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ResponseRateMost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ResponseRateMost.displayName = 'proto.org.couchers.api.requests.ResponseRateMost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.requests.ResponseRateAlmostAll, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.ResponseRateAlmostAll.displayName = 'proto.org.couchers.api.requests.ResponseRateAlmostAll';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.requests.GetResponseRateRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.requests.GetResponseRateRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.requests.GetResponseRateRes.displayName = 'proto.org.couchers.api.requests.GetResponseRateRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.CreateHostRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.CreateHostRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.CreateHostRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.CreateHostRequestReq;
  return proto.org.couchers.api.requests.CreateHostRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.CreateHostRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.CreateHostRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.CreateHostRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.CreateHostRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 host_user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.getHostUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq} returns this
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.setHostUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string from_date = 2;
 * @return {string}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.getFromDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq} returns this
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string to_date = 3;
 * @return {string}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.getToDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq} returns this
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.setToDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.CreateHostRequestReq} returns this
 */
proto.org.couchers.api.requests.CreateHostRequestReq.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.HostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.HostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.HostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.HostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    surferUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hostUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fromDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    toDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastSeenMessageId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    latestMessage: (f = msg.getLatestMessage()) && conversations_pb.Message.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.HostRequest}
 */
proto.org.couchers.api.requests.HostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.HostRequest;
  return proto.org.couchers.api.requests.HostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.HostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.HostRequest}
 */
proto.org.couchers.api.requests.HostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSurferUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setToDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeenMessageId(value);
      break;
    case 9:
      var value = new conversations_pb.Message;
      reader.readMessage(value,conversations_pb.Message.deserializeBinaryFromReader);
      msg.setLatestMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.HostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.HostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.HostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.HostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSurferUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHostUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFromDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getToDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastSeenMessageId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getLatestMessage();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      conversations_pb.Message.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 surfer_user_id = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getSurferUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setSurferUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 host_user_id = 3;
 * @return {number}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getHostUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setHostUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional org.couchers.api.conversations.HostRequestStatus status = 4;
 * @return {!proto.org.couchers.api.conversations.HostRequestStatus}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getStatus = function() {
  return /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.org.couchers.api.conversations.HostRequestStatus} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
*/
proto.org.couchers.api.requests.HostRequest.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.HostRequest.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string from_date = 6;
 * @return {string}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getFromDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string to_date = 7;
 * @return {string}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getToDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setToDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 last_seen_message_id = 8;
 * @return {number}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getLastSeenMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.setLastSeenMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional org.couchers.api.conversations.Message latest_message = 9;
 * @return {?proto.org.couchers.api.conversations.Message}
 */
proto.org.couchers.api.requests.HostRequest.prototype.getLatestMessage = function() {
  return /** @type{?proto.org.couchers.api.conversations.Message} */ (
    jspb.Message.getWrapperField(this, conversations_pb.Message, 9));
};


/**
 * @param {?proto.org.couchers.api.conversations.Message|undefined} value
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
*/
proto.org.couchers.api.requests.HostRequest.prototype.setLatestMessage = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.HostRequest} returns this
 */
proto.org.couchers.api.requests.HostRequest.prototype.clearLatestMessage = function() {
  return this.setLatestMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.HostRequest.prototype.hasLatestMessage = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetHostRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetHostRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetHostRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetHostRequestReq}
 */
proto.org.couchers.api.requests.GetHostRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetHostRequestReq;
  return proto.org.couchers.api.requests.GetHostRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetHostRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetHostRequestReq}
 */
proto.org.couchers.api.requests.GetHostRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetHostRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetHostRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetHostRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.CreateHostRequestRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.CreateHostRequestRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.CreateHostRequestRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.CreateHostRequestRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.CreateHostRequestRes}
 */
proto.org.couchers.api.requests.CreateHostRequestRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.CreateHostRequestRes;
  return proto.org.couchers.api.requests.CreateHostRequestRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.CreateHostRequestRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.CreateHostRequestRes}
 */
proto.org.couchers.api.requests.CreateHostRequestRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.CreateHostRequestRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.CreateHostRequestRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.CreateHostRequestRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.CreateHostRequestRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.CreateHostRequestRes.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.CreateHostRequestRes} returns this
 */
proto.org.couchers.api.requests.CreateHostRequestRes.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.RespondHostRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.RespondHostRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.RespondHostRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    text: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.RespondHostRequestReq}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.RespondHostRequestReq;
  return proto.org.couchers.api.requests.RespondHostRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.RespondHostRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.RespondHostRequestReq}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.RespondHostRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.RespondHostRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.RespondHostRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.RespondHostRequestReq} returns this
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional org.couchers.api.conversations.HostRequestStatus status = 2;
 * @return {!proto.org.couchers.api.conversations.HostRequestStatus}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.getStatus = function() {
  return /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.conversations.HostRequestStatus} value
 * @return {!proto.org.couchers.api.requests.RespondHostRequestReq} returns this
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.RespondHostRequestReq} returns this
 */
proto.org.couchers.api.requests.RespondHostRequestReq.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ListHostRequestsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ListHostRequestsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ListHostRequestsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    onlyActive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlySent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    onlyReceived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ListHostRequestsReq;
  return proto.org.couchers.api.requests.ListHostRequestsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ListHostRequestsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyActive(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlySent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyReceived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ListHostRequestsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ListHostRequestsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ListHostRequestsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOnlyActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOnlySent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOnlyReceived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 last_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.getLastRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.setLastRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 number = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool only_active = 3;
 * @return {boolean}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.getOnlyActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.setOnlyActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_sent = 4;
 * @return {boolean}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.getOnlySent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.setOnlySent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool only_received = 5;
 * @return {boolean}
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.getOnlyReceived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsReq} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsReq.prototype.setOnlyReceived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.requests.ListHostRequestsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ListHostRequestsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ListHostRequestsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ListHostRequestsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestsList: jspb.Message.toObjectList(msg.getHostRequestsList(),
    proto.org.couchers.api.requests.HostRequest.toObject, includeInstance),
    lastRequestId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    noMore: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ListHostRequestsRes;
  return proto.org.couchers.api.requests.ListHostRequestsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ListHostRequestsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.requests.HostRequest;
      reader.readMessage(value,proto.org.couchers.api.requests.HostRequest.deserializeBinaryFromReader);
      msg.addHostRequests(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastRequestId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ListHostRequestsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ListHostRequestsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ListHostRequestsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.requests.HostRequest.serializeBinaryToWriter
    );
  }
  f = message.getLastRequestId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNoMore();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated HostRequest host_requests = 1;
 * @return {!Array<!proto.org.couchers.api.requests.HostRequest>}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.getHostRequestsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.requests.HostRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.requests.HostRequest, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.requests.HostRequest>} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes} returns this
*/
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.setHostRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.requests.HostRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.requests.HostRequest}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.addHostRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.requests.HostRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.clearHostRequestsList = function() {
  return this.setHostRequestsList([]);
};


/**
 * optional int64 last_request_id = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.getLastRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.setLastRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool no_more = 3;
 * @return {boolean}
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.getNoMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.ListHostRequestsRes} returns this
 */
proto.org.couchers.api.requests.ListHostRequestsRes.prototype.setNoMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetHostRequestMessagesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastMessageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    number: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesReq}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetHostRequestMessagesReq;
  return proto.org.couchers.api.requests.GetHostRequestMessagesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesReq}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastMessageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetHostRequestMessagesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLastMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 last_message_id = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.getLastMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.setLastMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 number = 3;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesReq.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetHostRequestMessagesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    conversations_pb.Message.toObject, includeInstance),
    lastMessageId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    noMore: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetHostRequestMessagesRes;
  return proto.org.couchers.api.requests.GetHostRequestMessagesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new conversations_pb.Message;
      reader.readMessage(value,conversations_pb.Message.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastMessageId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetHostRequestMessagesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      conversations_pb.Message.serializeBinaryToWriter
    );
  }
  f = message.getLastMessageId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNoMore();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * repeated org.couchers.api.conversations.Message messages = 1;
 * @return {!Array<!proto.org.couchers.api.conversations.Message>}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.conversations.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, conversations_pb.Message, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.conversations.Message>} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} returns this
*/
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.conversations.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.conversations.Message}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.conversations.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional int64 last_message_id = 4;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.getLastMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.setLastMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool no_more = 5;
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.getNoMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestMessagesRes} returns this
 */
proto.org.couchers.api.requests.GetHostRequestMessagesRes.prototype.setNoMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.SendHostRequestMessageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.SendHostRequestMessageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.SendHostRequestMessageReq}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.SendHostRequestMessageReq;
  return proto.org.couchers.api.requests.SendHostRequestMessageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.SendHostRequestMessageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.SendHostRequestMessageReq}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.SendHostRequestMessageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.SendHostRequestMessageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.SendHostRequestMessageReq} returns this
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.requests.SendHostRequestMessageReq} returns this
 */
proto.org.couchers.api.requests.SendHostRequestMessageReq.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetHostRequestUpdatesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    newestMessageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    onlySent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlyReceived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetHostRequestUpdatesReq;
  return proto.org.couchers.api.requests.GetHostRequestUpdatesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewestMessageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlySent(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyReceived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetHostRequestUpdatesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewestMessageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOnlySent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOnlyReceived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 newest_message_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.getNewestMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.setNewestMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 number = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.setNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool only_sent = 3;
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.getOnlySent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.setOnlySent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_received = 4;
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.getOnlyReceived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesReq} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesReq.prototype.setOnlyReceived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.HostRequestUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.HostRequestUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.HostRequestUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: (f = msg.getMessage()) && conversations_pb.Message.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate}
 */
proto.org.couchers.api.requests.HostRequestUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.HostRequestUpdate;
  return proto.org.couchers.api.requests.HostRequestUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.HostRequestUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate}
 */
proto.org.couchers.api.requests.HostRequestUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = new conversations_pb.Message;
      reader.readMessage(value,conversations_pb.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.HostRequestUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.HostRequestUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.HostRequestUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      conversations_pb.Message.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate} returns this
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional org.couchers.api.conversations.Message message = 2;
 * @return {?proto.org.couchers.api.conversations.Message}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.getMessage = function() {
  return /** @type{?proto.org.couchers.api.conversations.Message} */ (
    jspb.Message.getWrapperField(this, conversations_pb.Message, 2));
};


/**
 * @param {?proto.org.couchers.api.conversations.Message|undefined} value
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate} returns this
*/
proto.org.couchers.api.requests.HostRequestUpdate.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate} returns this
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional org.couchers.api.conversations.HostRequestStatus status = 3;
 * @return {!proto.org.couchers.api.conversations.HostRequestStatus}
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.getStatus = function() {
  return /** @type {!proto.org.couchers.api.conversations.HostRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.org.couchers.api.conversations.HostRequestStatus} value
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate} returns this
 */
proto.org.couchers.api.requests.HostRequestUpdate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetHostRequestUpdatesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatesList: jspb.Message.toObjectList(msg.getUpdatesList(),
    proto.org.couchers.api.requests.HostRequestUpdate.toObject, includeInstance),
    noMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetHostRequestUpdatesRes;
  return proto.org.couchers.api.requests.GetHostRequestUpdatesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.requests.HostRequestUpdate;
      reader.readMessage(value,proto.org.couchers.api.requests.HostRequestUpdate.deserializeBinaryFromReader);
      msg.addUpdates(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetHostRequestUpdatesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.requests.HostRequestUpdate.serializeBinaryToWriter
    );
  }
  f = message.getNoMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated HostRequestUpdate updates = 1;
 * @return {!Array<!proto.org.couchers.api.requests.HostRequestUpdate>}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.getUpdatesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.requests.HostRequestUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.requests.HostRequestUpdate, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.requests.HostRequestUpdate>} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} returns this
*/
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.setUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.requests.HostRequestUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.requests.HostRequestUpdate}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.addUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.requests.HostRequestUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.clearUpdatesList = function() {
  return this.setUpdatesList([]);
};


/**
 * optional bool no_more = 2;
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.getNoMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.requests.GetHostRequestUpdatesRes} returns this
 */
proto.org.couchers.api.requests.GetHostRequestUpdatesRes.prototype.setNoMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lastSeenMessageId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.MarkLastSeenHostRequestReq;
  return proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastSeenMessageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLastSeenMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq} returns this
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 last_seen_message_id = 2;
 * @return {number}
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.getLastSeenMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.MarkLastSeenHostRequestReq} returns this
 */
proto.org.couchers.api.requests.MarkLastSeenHostRequestReq.prototype.setLastSeenMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetResponseRateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetResponseRateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetResponseRateReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetResponseRateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetResponseRateReq}
 */
proto.org.couchers.api.requests.GetResponseRateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetResponseRateReq;
  return proto.org.couchers.api.requests.GetResponseRateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetResponseRateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetResponseRateReq}
 */
proto.org.couchers.api.requests.GetResponseRateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetResponseRateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetResponseRateReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetResponseRateReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetResponseRateReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.requests.GetResponseRateReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateReq} returns this
 */
proto.org.couchers.api.requests.GetResponseRateReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ResponseRateInsufficientData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ResponseRateInsufficientData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ResponseRateInsufficientData}
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ResponseRateInsufficientData;
  return proto.org.couchers.api.requests.ResponseRateInsufficientData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ResponseRateInsufficientData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ResponseRateInsufficientData}
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ResponseRateInsufficientData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ResponseRateInsufficientData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateInsufficientData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ResponseRateLow.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ResponseRateLow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ResponseRateLow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateLow.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ResponseRateLow}
 */
proto.org.couchers.api.requests.ResponseRateLow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ResponseRateLow;
  return proto.org.couchers.api.requests.ResponseRateLow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ResponseRateLow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ResponseRateLow}
 */
proto.org.couchers.api.requests.ResponseRateLow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ResponseRateLow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ResponseRateLow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ResponseRateLow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateLow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ResponseRateSome.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ResponseRateSome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ResponseRateSome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateSome.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseTimeP33: (f = msg.getResponseTimeP33()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ResponseRateSome}
 */
proto.org.couchers.api.requests.ResponseRateSome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ResponseRateSome;
  return proto.org.couchers.api.requests.ResponseRateSome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ResponseRateSome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ResponseRateSome}
 */
proto.org.couchers.api.requests.ResponseRateSome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setResponseTimeP33(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ResponseRateSome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ResponseRateSome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ResponseRateSome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateSome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseTimeP33();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration response_time_p33 = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.org.couchers.api.requests.ResponseRateSome.prototype.getResponseTimeP33 = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.org.couchers.api.requests.ResponseRateSome} returns this
*/
proto.org.couchers.api.requests.ResponseRateSome.prototype.setResponseTimeP33 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.ResponseRateSome} returns this
 */
proto.org.couchers.api.requests.ResponseRateSome.prototype.clearResponseTimeP33 = function() {
  return this.setResponseTimeP33(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.ResponseRateSome.prototype.hasResponseTimeP33 = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ResponseRateMost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ResponseRateMost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateMost.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseTimeP33: (f = msg.getResponseTimeP33()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    responseTimeP66: (f = msg.getResponseTimeP66()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ResponseRateMost}
 */
proto.org.couchers.api.requests.ResponseRateMost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ResponseRateMost;
  return proto.org.couchers.api.requests.ResponseRateMost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ResponseRateMost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ResponseRateMost}
 */
proto.org.couchers.api.requests.ResponseRateMost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setResponseTimeP33(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setResponseTimeP66(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ResponseRateMost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ResponseRateMost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateMost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseTimeP33();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getResponseTimeP66();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration response_time_p33 = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.getResponseTimeP33 = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.org.couchers.api.requests.ResponseRateMost} returns this
*/
proto.org.couchers.api.requests.ResponseRateMost.prototype.setResponseTimeP33 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.ResponseRateMost} returns this
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.clearResponseTimeP33 = function() {
  return this.setResponseTimeP33(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.hasResponseTimeP33 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Duration response_time_p66 = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.getResponseTimeP66 = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.org.couchers.api.requests.ResponseRateMost} returns this
*/
proto.org.couchers.api.requests.ResponseRateMost.prototype.setResponseTimeP66 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.ResponseRateMost} returns this
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.clearResponseTimeP66 = function() {
  return this.setResponseTimeP66(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.ResponseRateMost.prototype.hasResponseTimeP66 = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.ResponseRateAlmostAll.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.ResponseRateAlmostAll} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseTimeP33: (f = msg.getResponseTimeP33()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    responseTimeP66: (f = msg.getResponseTimeP66()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.ResponseRateAlmostAll;
  return proto.org.couchers.api.requests.ResponseRateAlmostAll.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.ResponseRateAlmostAll} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setResponseTimeP33(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setResponseTimeP66(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.ResponseRateAlmostAll.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.ResponseRateAlmostAll} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseTimeP33();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getResponseTimeP66();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration response_time_p33 = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.getResponseTimeP33 = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll} returns this
*/
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.setResponseTimeP33 = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll} returns this
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.clearResponseTimeP33 = function() {
  return this.setResponseTimeP33(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.hasResponseTimeP33 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Duration response_time_p66 = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.getResponseTimeP66 = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll} returns this
*/
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.setResponseTimeP66 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.ResponseRateAlmostAll} returns this
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.clearResponseTimeP66 = function() {
  return this.setResponseTimeP66(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.ResponseRateAlmostAll.prototype.hasResponseTimeP66 = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.org.couchers.api.requests.GetResponseRateRes.ResponseRateCase = {
  RESPONSE_RATE_NOT_SET: 0,
  INSUFFICIENT_DATA: 1,
  LOW: 2,
  SOME: 3,
  MOST: 4,
  ALMOST_ALL: 5
};

/**
 * @return {proto.org.couchers.api.requests.GetResponseRateRes.ResponseRateCase}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getResponseRateCase = function() {
  return /** @type {proto.org.couchers.api.requests.GetResponseRateRes.ResponseRateCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.requests.GetResponseRateRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.requests.GetResponseRateRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetResponseRateRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    insufficientData: (f = msg.getInsufficientData()) && proto.org.couchers.api.requests.ResponseRateInsufficientData.toObject(includeInstance, f),
    low: (f = msg.getLow()) && proto.org.couchers.api.requests.ResponseRateLow.toObject(includeInstance, f),
    some: (f = msg.getSome()) && proto.org.couchers.api.requests.ResponseRateSome.toObject(includeInstance, f),
    most: (f = msg.getMost()) && proto.org.couchers.api.requests.ResponseRateMost.toObject(includeInstance, f),
    almostAll: (f = msg.getAlmostAll()) && proto.org.couchers.api.requests.ResponseRateAlmostAll.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes}
 */
proto.org.couchers.api.requests.GetResponseRateRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.requests.GetResponseRateRes;
  return proto.org.couchers.api.requests.GetResponseRateRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.requests.GetResponseRateRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes}
 */
proto.org.couchers.api.requests.GetResponseRateRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.requests.ResponseRateInsufficientData;
      reader.readMessage(value,proto.org.couchers.api.requests.ResponseRateInsufficientData.deserializeBinaryFromReader);
      msg.setInsufficientData(value);
      break;
    case 2:
      var value = new proto.org.couchers.api.requests.ResponseRateLow;
      reader.readMessage(value,proto.org.couchers.api.requests.ResponseRateLow.deserializeBinaryFromReader);
      msg.setLow(value);
      break;
    case 3:
      var value = new proto.org.couchers.api.requests.ResponseRateSome;
      reader.readMessage(value,proto.org.couchers.api.requests.ResponseRateSome.deserializeBinaryFromReader);
      msg.setSome(value);
      break;
    case 4:
      var value = new proto.org.couchers.api.requests.ResponseRateMost;
      reader.readMessage(value,proto.org.couchers.api.requests.ResponseRateMost.deserializeBinaryFromReader);
      msg.setMost(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.requests.ResponseRateAlmostAll;
      reader.readMessage(value,proto.org.couchers.api.requests.ResponseRateAlmostAll.deserializeBinaryFromReader);
      msg.setAlmostAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.requests.GetResponseRateRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.requests.GetResponseRateRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.requests.GetResponseRateRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsufficientData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.org.couchers.api.requests.ResponseRateInsufficientData.serializeBinaryToWriter
    );
  }
  f = message.getLow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.org.couchers.api.requests.ResponseRateLow.serializeBinaryToWriter
    );
  }
  f = message.getSome();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.org.couchers.api.requests.ResponseRateSome.serializeBinaryToWriter
    );
  }
  f = message.getMost();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.api.requests.ResponseRateMost.serializeBinaryToWriter
    );
  }
  f = message.getAlmostAll();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.requests.ResponseRateAlmostAll.serializeBinaryToWriter
    );
  }
};


/**
 * optional ResponseRateInsufficientData insufficient_data = 1;
 * @return {?proto.org.couchers.api.requests.ResponseRateInsufficientData}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getInsufficientData = function() {
  return /** @type{?proto.org.couchers.api.requests.ResponseRateInsufficientData} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.requests.ResponseRateInsufficientData, 1));
};


/**
 * @param {?proto.org.couchers.api.requests.ResponseRateInsufficientData|undefined} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
*/
proto.org.couchers.api.requests.GetResponseRateRes.prototype.setInsufficientData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.clearInsufficientData = function() {
  return this.setInsufficientData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.hasInsufficientData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ResponseRateLow low = 2;
 * @return {?proto.org.couchers.api.requests.ResponseRateLow}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getLow = function() {
  return /** @type{?proto.org.couchers.api.requests.ResponseRateLow} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.requests.ResponseRateLow, 2));
};


/**
 * @param {?proto.org.couchers.api.requests.ResponseRateLow|undefined} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
*/
proto.org.couchers.api.requests.GetResponseRateRes.prototype.setLow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.clearLow = function() {
  return this.setLow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.hasLow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ResponseRateSome some = 3;
 * @return {?proto.org.couchers.api.requests.ResponseRateSome}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getSome = function() {
  return /** @type{?proto.org.couchers.api.requests.ResponseRateSome} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.requests.ResponseRateSome, 3));
};


/**
 * @param {?proto.org.couchers.api.requests.ResponseRateSome|undefined} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
*/
proto.org.couchers.api.requests.GetResponseRateRes.prototype.setSome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.clearSome = function() {
  return this.setSome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.hasSome = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ResponseRateMost most = 4;
 * @return {?proto.org.couchers.api.requests.ResponseRateMost}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getMost = function() {
  return /** @type{?proto.org.couchers.api.requests.ResponseRateMost} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.requests.ResponseRateMost, 4));
};


/**
 * @param {?proto.org.couchers.api.requests.ResponseRateMost|undefined} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
*/
proto.org.couchers.api.requests.GetResponseRateRes.prototype.setMost = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.clearMost = function() {
  return this.setMost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.hasMost = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ResponseRateAlmostAll almost_all = 5;
 * @return {?proto.org.couchers.api.requests.ResponseRateAlmostAll}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.getAlmostAll = function() {
  return /** @type{?proto.org.couchers.api.requests.ResponseRateAlmostAll} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.requests.ResponseRateAlmostAll, 5));
};


/**
 * @param {?proto.org.couchers.api.requests.ResponseRateAlmostAll|undefined} value
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
*/
proto.org.couchers.api.requests.GetResponseRateRes.prototype.setAlmostAll = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.org.couchers.api.requests.GetResponseRateRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.requests.GetResponseRateRes} returns this
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.clearAlmostAll = function() {
  return this.setAlmostAll(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.requests.GetResponseRateRes.prototype.hasAlmostAll = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.org.couchers.api.requests.ResponseRate = {
  RESPONSE_RATE_UNSPECIFIED: 0
};

goog.object.extend(exports, proto.org.couchers.api.requests);

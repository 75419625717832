/**
 * @fileoverview gRPC-Web generated client stub for org.couchers.notifications
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: notifications.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var annotations_pb = require('./annotations_pb.js')
const proto = {};
proto.org = {};
proto.org.couchers = {};
proto.org.couchers.notifications = require('./notifications_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.notifications.NotificationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.notifications.NotificationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.notifications.GetNotificationSettingsReq,
 *   !proto.org.couchers.notifications.GetNotificationSettingsRes>}
 */
const methodDescriptor_Notifications_GetNotificationSettings = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/GetNotificationSettings',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.notifications.GetNotificationSettingsReq,
  proto.org.couchers.notifications.GetNotificationSettingsRes,
  /**
   * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.notifications.GetNotificationSettingsRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.notifications.GetNotificationSettingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.notifications.GetNotificationSettingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.getNotificationSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/GetNotificationSettings',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetNotificationSettings,
      callback);
};


/**
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.notifications.GetNotificationSettingsRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.getNotificationSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/GetNotificationSettings',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetNotificationSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.notifications.SetNotificationSettingsReq,
 *   !proto.org.couchers.notifications.GetNotificationSettingsRes>}
 */
const methodDescriptor_Notifications_SetNotificationSettings = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/SetNotificationSettings',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.notifications.SetNotificationSettingsReq,
  proto.org.couchers.notifications.GetNotificationSettingsRes,
  /**
   * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.notifications.GetNotificationSettingsRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.notifications.GetNotificationSettingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.notifications.GetNotificationSettingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.setNotificationSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/SetNotificationSettings',
      request,
      metadata || {},
      methodDescriptor_Notifications_SetNotificationSettings,
      callback);
};


/**
 * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.notifications.GetNotificationSettingsRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.setNotificationSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/SetNotificationSettings',
      request,
      metadata || {},
      methodDescriptor_Notifications_SetNotificationSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.notifications.ListNotificationsReq,
 *   !proto.org.couchers.notifications.ListNotificationsRes>}
 */
const methodDescriptor_Notifications_ListNotifications = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/ListNotifications',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.notifications.ListNotificationsReq,
  proto.org.couchers.notifications.ListNotificationsRes,
  /**
   * @param {!proto.org.couchers.notifications.ListNotificationsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.notifications.ListNotificationsRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.notifications.ListNotificationsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.notifications.ListNotificationsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.notifications.ListNotificationsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.listNotifications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/ListNotifications',
      request,
      metadata || {},
      methodDescriptor_Notifications_ListNotifications,
      callback);
};


/**
 * @param {!proto.org.couchers.notifications.ListNotificationsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.notifications.ListNotificationsRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.listNotifications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/ListNotifications',
      request,
      metadata || {},
      methodDescriptor_Notifications_ListNotifications);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.org.couchers.notifications.GetVapidPublicKeyRes>}
 */
const methodDescriptor_Notifications_GetVapidPublicKey = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/GetVapidPublicKey',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.org.couchers.notifications.GetVapidPublicKeyRes,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.notifications.GetVapidPublicKeyRes.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.notifications.GetVapidPublicKeyRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.notifications.GetVapidPublicKeyRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.getVapidPublicKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/GetVapidPublicKey',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetVapidPublicKey,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.notifications.GetVapidPublicKeyRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.getVapidPublicKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/GetVapidPublicKey',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetVapidPublicKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.notifications.RegisterPushNotificationSubscriptionReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Notifications_RegisterPushNotificationSubscription = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/RegisterPushNotificationSubscription',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.notifications.RegisterPushNotificationSubscriptionReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.org.couchers.notifications.RegisterPushNotificationSubscriptionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.org.couchers.notifications.RegisterPushNotificationSubscriptionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.registerPushNotificationSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/RegisterPushNotificationSubscription',
      request,
      metadata || {},
      methodDescriptor_Notifications_RegisterPushNotificationSubscription,
      callback);
};


/**
 * @param {!proto.org.couchers.notifications.RegisterPushNotificationSubscriptionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.registerPushNotificationSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/RegisterPushNotificationSubscription',
      request,
      metadata || {},
      methodDescriptor_Notifications_RegisterPushNotificationSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Notifications_SendTestPushNotification = new grpc.web.MethodDescriptor(
  '/org.couchers.notifications.Notifications/SendTestPushNotification',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.notifications.NotificationsClient.prototype.sendTestPushNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/SendTestPushNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_SendTestPushNotification,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.notifications.NotificationsPromiseClient.prototype.sendTestPushNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.notifications.Notifications/SendTestPushNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_SendTestPushNotification);
};


module.exports = proto.org.couchers.notifications;


// source: pages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var threads_pb = require('./threads_pb.js');
goog.object.extend(proto, threads_pb);
goog.exportSymbol('proto.org.couchers.api.pages.Coordinate', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.CreateGuideReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.CreatePlaceReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.GetPageReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.ListUserGuidesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.ListUserGuidesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.ListUserPlacesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.ListUserPlacesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.Page', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.Page.OwnerCase', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.PageType', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.TransferPageReq', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.TransferPageReq.NewOwnerCase', null, global);
goog.exportSymbol('proto.org.couchers.api.pages.UpdatePageReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.Coordinate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.Coordinate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.Coordinate.displayName = 'proto.org.couchers.api.pages.Coordinate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.pages.Page.repeatedFields_, proto.org.couchers.api.pages.Page.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.pages.Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.Page.displayName = 'proto.org.couchers.api.pages.Page';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.CreatePlaceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.CreatePlaceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.CreatePlaceReq.displayName = 'proto.org.couchers.api.pages.CreatePlaceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.CreateGuideReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.CreateGuideReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.CreateGuideReq.displayName = 'proto.org.couchers.api.pages.CreateGuideReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.GetPageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.GetPageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.GetPageReq.displayName = 'proto.org.couchers.api.pages.GetPageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.UpdatePageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.UpdatePageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.UpdatePageReq.displayName = 'proto.org.couchers.api.pages.UpdatePageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.TransferPageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.pages.TransferPageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.TransferPageReq.displayName = 'proto.org.couchers.api.pages.TransferPageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.ListUserPlacesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.ListUserPlacesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.ListUserPlacesReq.displayName = 'proto.org.couchers.api.pages.ListUserPlacesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.ListUserPlacesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.pages.ListUserPlacesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.pages.ListUserPlacesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.ListUserPlacesRes.displayName = 'proto.org.couchers.api.pages.ListUserPlacesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.ListUserGuidesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.pages.ListUserGuidesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.ListUserGuidesReq.displayName = 'proto.org.couchers.api.pages.ListUserGuidesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.pages.ListUserGuidesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.pages.ListUserGuidesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.pages.ListUserGuidesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.pages.ListUserGuidesRes.displayName = 'proto.org.couchers.api.pages.ListUserGuidesRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.Coordinate.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.Coordinate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.Coordinate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.Coordinate.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.Coordinate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.Coordinate;
  return proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.Coordinate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.Coordinate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.Coordinate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.Coordinate.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Coordinate} returns this
 */
proto.org.couchers.api.pages.Coordinate.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lng = 2;
 * @return {number}
 */
proto.org.couchers.api.pages.Coordinate.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Coordinate} returns this
 */
proto.org.couchers.api.pages.Coordinate.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.pages.Page.repeatedFields_ = [15];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.pages.Page.oneofGroups_ = [[8,17,9]];

/**
 * @enum {number}
 */
proto.org.couchers.api.pages.Page.OwnerCase = {
  OWNER_NOT_SET: 0,
  OWNER_USER_ID: 8,
  OWNER_COMMUNITY_ID: 17,
  OWNER_GROUP_ID: 9
};

/**
 * @return {proto.org.couchers.api.pages.Page.OwnerCase}
 */
proto.org.couchers.api.pages.Page.prototype.getOwnerCase = function() {
  return /** @type {proto.org.couchers.api.pages.Page.OwnerCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.pages.Page.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.Page.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastEdited: (f = msg.getLastEdited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastEditorUserId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creatorUserId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ownerUserId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ownerCommunityId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    ownerGroupId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    thread: (f = msg.getThread()) && threads_pb.Thread.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 11, ""),
    content: jspb.Message.getFieldWithDefault(msg, 12, ""),
    photoUrl: jspb.Message.getFieldWithDefault(msg, 19, ""),
    address: jspb.Message.getFieldWithDefault(msg, 13, ""),
    location: (f = msg.getLocation()) && proto.org.couchers.api.pages.Coordinate.toObject(includeInstance, f),
    editorUserIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    canEdit: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    canModerate: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.pages.Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.Page;
  return proto.org.couchers.api.pages.Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.pages.Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.pages.PageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEdited(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastEditorUserId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatorUserId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerUserId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerCommunityId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOwnerGroupId(value);
      break;
    case 20:
      var value = new threads_pb.Thread;
      reader.readMessage(value,threads_pb.Thread.deserializeBinaryFromReader);
      msg.setThread(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 14:
      var value = new proto.org.couchers.api.pages.Coordinate;
      reader.readMessage(value,proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEditorUserIds(values[i]);
      }
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEdit(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanModerate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastEdited();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastEditorUserId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreatorUserId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getThread();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      threads_pb.Thread.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPhotoUrl();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter
    );
  }
  f = message.getEditorUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getCanEdit();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCanModerate();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional int64 page_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getPageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setPageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PageType type = 2;
 * @return {!proto.org.couchers.api.pages.PageType}
 */
proto.org.couchers.api.pages.Page.prototype.getType = function() {
  return /** @type {!proto.org.couchers.api.pages.PageType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.pages.PageType} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string slug = 3;
 * @return {string}
 */
proto.org.couchers.api.pages.Page.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.pages.Page.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
*/
proto.org.couchers.api.pages.Page.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp last_edited = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.pages.Page.prototype.getLastEdited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
*/
proto.org.couchers.api.pages.Page.prototype.setLastEdited = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearLastEdited = function() {
  return this.setLastEdited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasLastEdited = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 last_editor_user_id = 6;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getLastEditorUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setLastEditorUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 creator_user_id = 7;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getCreatorUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setCreatorUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 owner_user_id = 8;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getOwnerUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setOwnerUserId = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.org.couchers.api.pages.Page.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearOwnerUserId = function() {
  return jspb.Message.setOneofField(this, 8, proto.org.couchers.api.pages.Page.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasOwnerUserId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 owner_community_id = 17;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getOwnerCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setOwnerCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 17, proto.org.couchers.api.pages.Page.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearOwnerCommunityId = function() {
  return jspb.Message.setOneofField(this, 17, proto.org.couchers.api.pages.Page.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasOwnerCommunityId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 owner_group_id = 9;
 * @return {number}
 */
proto.org.couchers.api.pages.Page.prototype.getOwnerGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setOwnerGroupId = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.org.couchers.api.pages.Page.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearOwnerGroupId = function() {
  return jspb.Message.setOneofField(this, 9, proto.org.couchers.api.pages.Page.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasOwnerGroupId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional org.couchers.api.threads.Thread thread = 20;
 * @return {?proto.org.couchers.api.threads.Thread}
 */
proto.org.couchers.api.pages.Page.prototype.getThread = function() {
  return /** @type{?proto.org.couchers.api.threads.Thread} */ (
    jspb.Message.getWrapperField(this, threads_pb.Thread, 20));
};


/**
 * @param {?proto.org.couchers.api.threads.Thread|undefined} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
*/
proto.org.couchers.api.pages.Page.prototype.setThread = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearThread = function() {
  return this.setThread(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasThread = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string title = 11;
 * @return {string}
 */
proto.org.couchers.api.pages.Page.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string content = 12;
 * @return {string}
 */
proto.org.couchers.api.pages.Page.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string photo_url = 19;
 * @return {string}
 */
proto.org.couchers.api.pages.Page.prototype.getPhotoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setPhotoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string address = 13;
 * @return {string}
 */
proto.org.couchers.api.pages.Page.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Coordinate location = 14;
 * @return {?proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.Page.prototype.getLocation = function() {
  return /** @type{?proto.org.couchers.api.pages.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.pages.Coordinate, 14));
};


/**
 * @param {?proto.org.couchers.api.pages.Coordinate|undefined} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
*/
proto.org.couchers.api.pages.Page.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated int64 editor_user_ids = 15;
 * @return {!Array<number>}
 */
proto.org.couchers.api.pages.Page.prototype.getEditorUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setEditorUserIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.addEditorUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.clearEditorUserIdsList = function() {
  return this.setEditorUserIdsList([]);
};


/**
 * optional bool can_edit = 16;
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.getCanEdit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setCanEdit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool can_moderate = 18;
 * @return {boolean}
 */
proto.org.couchers.api.pages.Page.prototype.getCanModerate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.pages.Page} returns this
 */
proto.org.couchers.api.pages.Page.prototype.setCanModerate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.CreatePlaceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.CreatePlaceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.CreatePlaceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    photoKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.org.couchers.api.pages.Coordinate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq}
 */
proto.org.couchers.api.pages.CreatePlaceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.CreatePlaceReq;
  return proto.org.couchers.api.pages.CreatePlaceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.CreatePlaceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq}
 */
proto.org.couchers.api.pages.CreatePlaceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = new proto.org.couchers.api.pages.Coordinate;
      reader.readMessage(value,proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.CreatePlaceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.CreatePlaceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.CreatePlaceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhotoKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string photo_key = 5;
 * @return {string}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.getPhotoKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Coordinate location = 4;
 * @return {?proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.getLocation = function() {
  return /** @type{?proto.org.couchers.api.pages.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.pages.Coordinate, 4));
};


/**
 * @param {?proto.org.couchers.api.pages.Coordinate|undefined} value
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
*/
proto.org.couchers.api.pages.CreatePlaceReq.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.CreatePlaceReq} returns this
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.CreatePlaceReq.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.CreateGuideReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.CreateGuideReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.CreateGuideReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    photoKey: jspb.Message.getFieldWithDefault(msg, 6, ""),
    parentCommunityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    location: (f = msg.getLocation()) && proto.org.couchers.api.pages.Coordinate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.CreateGuideReq}
 */
proto.org.couchers.api.pages.CreateGuideReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.CreateGuideReq;
  return proto.org.couchers.api.pages.CreateGuideReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.CreateGuideReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.CreateGuideReq}
 */
proto.org.couchers.api.pages.CreateGuideReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentCommunityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.pages.Coordinate;
      reader.readMessage(value,proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.CreateGuideReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.CreateGuideReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.CreateGuideReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhotoKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getParentCommunityId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string photo_key = 6;
 * @return {string}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getPhotoKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 parent_community_id = 3;
 * @return {number}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getParentCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.setParentCommunityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Coordinate location = 5;
 * @return {?proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.getLocation = function() {
  return /** @type{?proto.org.couchers.api.pages.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.pages.Coordinate, 5));
};


/**
 * @param {?proto.org.couchers.api.pages.Coordinate|undefined} value
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
*/
proto.org.couchers.api.pages.CreateGuideReq.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.CreateGuideReq} returns this
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.CreateGuideReq.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.GetPageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.GetPageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.GetPageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.GetPageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.GetPageReq}
 */
proto.org.couchers.api.pages.GetPageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.GetPageReq;
  return proto.org.couchers.api.pages.GetPageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.GetPageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.GetPageReq}
 */
proto.org.couchers.api.pages.GetPageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.GetPageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.GetPageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.GetPageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.GetPageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 page_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.GetPageReq.prototype.getPageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.GetPageReq} returns this
 */
proto.org.couchers.api.pages.GetPageReq.prototype.setPageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.UpdatePageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.UpdatePageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.UpdatePageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    content: (f = msg.getContent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    photoKey: (f = msg.getPhotoKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.org.couchers.api.pages.Coordinate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq}
 */
proto.org.couchers.api.pages.UpdatePageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.UpdatePageReq;
  return proto.org.couchers.api.pages.UpdatePageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.UpdatePageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq}
 */
proto.org.couchers.api.pages.UpdatePageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhotoKey(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.pages.Coordinate;
      reader.readMessage(value,proto.org.couchers.api.pages.Coordinate.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.UpdatePageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.UpdatePageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.UpdatePageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhotoKey();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.pages.Coordinate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 page_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getPageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.setPageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
*/
proto.org.couchers.api.pages.UpdatePageReq.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue content = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getContent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
*/
proto.org.couchers.api.pages.UpdatePageReq.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.hasContent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue photo_key = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getPhotoKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
*/
proto.org.couchers.api.pages.UpdatePageReq.prototype.setPhotoKey = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.clearPhotoKey = function() {
  return this.setPhotoKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.hasPhotoKey = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue address = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
*/
proto.org.couchers.api.pages.UpdatePageReq.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Coordinate location = 5;
 * @return {?proto.org.couchers.api.pages.Coordinate}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.getLocation = function() {
  return /** @type{?proto.org.couchers.api.pages.Coordinate} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.pages.Coordinate, 5));
};


/**
 * @param {?proto.org.couchers.api.pages.Coordinate|undefined} value
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
*/
proto.org.couchers.api.pages.UpdatePageReq.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.pages.UpdatePageReq} returns this
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.UpdatePageReq.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.pages.TransferPageReq.oneofGroups_ = [[3,2]];

/**
 * @enum {number}
 */
proto.org.couchers.api.pages.TransferPageReq.NewOwnerCase = {
  NEW_OWNER_NOT_SET: 0,
  NEW_OWNER_COMMUNITY_ID: 3,
  NEW_OWNER_GROUP_ID: 2
};

/**
 * @return {proto.org.couchers.api.pages.TransferPageReq.NewOwnerCase}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.getNewOwnerCase = function() {
  return /** @type {proto.org.couchers.api.pages.TransferPageReq.NewOwnerCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.TransferPageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.TransferPageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.TransferPageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newOwnerCommunityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    newOwnerGroupId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.TransferPageReq}
 */
proto.org.couchers.api.pages.TransferPageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.TransferPageReq;
  return proto.org.couchers.api.pages.TransferPageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.TransferPageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.TransferPageReq}
 */
proto.org.couchers.api.pages.TransferPageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewOwnerCommunityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNewOwnerGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.TransferPageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.TransferPageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.TransferPageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 page_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.getPageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.TransferPageReq} returns this
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.setPageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 new_owner_community_id = 3;
 * @return {number}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.getNewOwnerCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.TransferPageReq} returns this
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.setNewOwnerCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.pages.TransferPageReq} returns this
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.clearNewOwnerCommunityId = function() {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.hasNewOwnerCommunityId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 new_owner_group_id = 2;
 * @return {number}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.getNewOwnerGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.TransferPageReq} returns this
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.setNewOwnerGroupId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.pages.TransferPageReq} returns this
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.clearNewOwnerGroupId = function() {
  return jspb.Message.setOneofField(this, 2, proto.org.couchers.api.pages.TransferPageReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.pages.TransferPageReq.prototype.hasNewOwnerGroupId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.ListUserPlacesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.ListUserPlacesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserPlacesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.ListUserPlacesReq}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.ListUserPlacesReq;
  return proto.org.couchers.api.pages.ListUserPlacesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.ListUserPlacesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.ListUserPlacesReq}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.ListUserPlacesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.ListUserPlacesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserPlacesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.ListUserPlacesReq} returns this
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.ListUserPlacesReq} returns this
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.ListUserPlacesReq} returns this
 */
proto.org.couchers.api.pages.ListUserPlacesReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.pages.ListUserPlacesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.ListUserPlacesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.ListUserPlacesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserPlacesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    placesList: jspb.Message.toObjectList(msg.getPlacesList(),
    proto.org.couchers.api.pages.Page.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.ListUserPlacesRes}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.ListUserPlacesRes;
  return proto.org.couchers.api.pages.ListUserPlacesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.ListUserPlacesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.ListUserPlacesRes}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.pages.Page;
      reader.readMessage(value,proto.org.couchers.api.pages.Page.deserializeBinaryFromReader);
      msg.addPlaces(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.ListUserPlacesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.ListUserPlacesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserPlacesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.pages.Page.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Page places = 1;
 * @return {!Array<!proto.org.couchers.api.pages.Page>}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.getPlacesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.pages.Page>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.pages.Page, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.pages.Page>} value
 * @return {!proto.org.couchers.api.pages.ListUserPlacesRes} returns this
*/
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.setPlacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.pages.Page=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.addPlaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.pages.Page, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.pages.ListUserPlacesRes} returns this
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.clearPlacesList = function() {
  return this.setPlacesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.ListUserPlacesRes} returns this
 */
proto.org.couchers.api.pages.ListUserPlacesRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.ListUserGuidesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.ListUserGuidesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserGuidesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.ListUserGuidesReq}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.ListUserGuidesReq;
  return proto.org.couchers.api.pages.ListUserGuidesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.ListUserGuidesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.ListUserGuidesReq}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.ListUserGuidesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.ListUserGuidesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserGuidesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.ListUserGuidesReq} returns this
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.pages.ListUserGuidesReq} returns this
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.ListUserGuidesReq} returns this
 */
proto.org.couchers.api.pages.ListUserGuidesReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.pages.ListUserGuidesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.pages.ListUserGuidesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.pages.ListUserGuidesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserGuidesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    guidesList: jspb.Message.toObjectList(msg.getGuidesList(),
    proto.org.couchers.api.pages.Page.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.pages.ListUserGuidesRes}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.pages.ListUserGuidesRes;
  return proto.org.couchers.api.pages.ListUserGuidesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.pages.ListUserGuidesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.pages.ListUserGuidesRes}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.pages.Page;
      reader.readMessage(value,proto.org.couchers.api.pages.Page.deserializeBinaryFromReader);
      msg.addGuides(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.pages.ListUserGuidesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.pages.ListUserGuidesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.pages.ListUserGuidesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuidesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.pages.Page.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Page guides = 1;
 * @return {!Array<!proto.org.couchers.api.pages.Page>}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.getGuidesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.pages.Page>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.pages.Page, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.pages.Page>} value
 * @return {!proto.org.couchers.api.pages.ListUserGuidesRes} returns this
*/
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.setGuidesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.pages.Page=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.addGuides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.pages.Page, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.pages.ListUserGuidesRes} returns this
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.clearGuidesList = function() {
  return this.setGuidesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.pages.ListUserGuidesRes} returns this
 */
proto.org.couchers.api.pages.ListUserGuidesRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.org.couchers.api.pages.PageType = {
  PAGE_TYPE_PLACE: 0,
  PAGE_TYPE_GUIDE: 1,
  PAGE_TYPE_MAIN_PAGE: 2
};

goog.object.extend(exports, proto.org.couchers.api.pages);

/**
 * @fileoverview gRPC-Web generated client stub for org.couchers.auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.0
// source: auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var annotations_pb = require('./annotations_pb.js')

var api_pb = require('./api_pb.js')
const proto = {};
proto.org = {};
proto.org.couchers = {};
proto.org.couchers.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.auth.AuthClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.org.couchers.auth.AuthPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.SignupFlowReq,
 *   !proto.org.couchers.auth.SignupFlowRes>}
 */
const methodDescriptor_Auth_SignupFlow = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/SignupFlow',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.SignupFlowReq,
  proto.org.couchers.auth.SignupFlowRes,
  /**
   * @param {!proto.org.couchers.auth.SignupFlowReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.SignupFlowRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.SignupFlowReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.SignupFlowRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.SignupFlowRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.signupFlow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/SignupFlow',
      request,
      metadata || {},
      methodDescriptor_Auth_SignupFlow,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.SignupFlowReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.SignupFlowRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.signupFlow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/SignupFlow',
      request,
      metadata || {},
      methodDescriptor_Auth_SignupFlow);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.UsernameValidReq,
 *   !proto.org.couchers.auth.UsernameValidRes>}
 */
const methodDescriptor_Auth_UsernameValid = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/UsernameValid',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.UsernameValidReq,
  proto.org.couchers.auth.UsernameValidRes,
  /**
   * @param {!proto.org.couchers.auth.UsernameValidReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.UsernameValidRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.UsernameValidReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.UsernameValidRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.UsernameValidRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.usernameValid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/UsernameValid',
      request,
      metadata || {},
      methodDescriptor_Auth_UsernameValid,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.UsernameValidReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.UsernameValidRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.usernameValid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/UsernameValid',
      request,
      metadata || {},
      methodDescriptor_Auth_UsernameValid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.AuthReq,
 *   !proto.org.couchers.auth.AuthRes>}
 */
const methodDescriptor_Auth_Authenticate = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/Authenticate',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.AuthReq,
  proto.org.couchers.auth.AuthRes,
  /**
   * @param {!proto.org.couchers.auth.AuthReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.AuthRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.AuthReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.AuthRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.AuthRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.authenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/Authenticate',
      request,
      metadata || {},
      methodDescriptor_Auth_Authenticate,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.AuthReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.AuthRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.authenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/Authenticate',
      request,
      metadata || {},
      methodDescriptor_Auth_Authenticate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.org.couchers.auth.GetAuthStateRes>}
 */
const methodDescriptor_Auth_GetAuthState = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/GetAuthState',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.org.couchers.auth.GetAuthStateRes,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.GetAuthStateRes.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.GetAuthStateRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.GetAuthStateRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.getAuthState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/GetAuthState',
      request,
      metadata || {},
      methodDescriptor_Auth_GetAuthState,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.GetAuthStateRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.getAuthState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/GetAuthState',
      request,
      metadata || {},
      methodDescriptor_Auth_GetAuthState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Auth_Deauthenticate = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/Deauthenticate',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.deauthenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/Deauthenticate',
      request,
      metadata || {},
      methodDescriptor_Auth_Deauthenticate,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.deauthenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/Deauthenticate',
      request,
      metadata || {},
      methodDescriptor_Auth_Deauthenticate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.ResetPasswordReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Auth_ResetPassword = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.ResetPasswordReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.org.couchers.auth.ResetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.ResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_Auth_ResetPassword,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.ResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_Auth_ResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.CompletePasswordResetV2Req,
 *   !proto.org.couchers.auth.AuthRes>}
 */
const methodDescriptor_Auth_CompletePasswordResetV2 = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/CompletePasswordResetV2',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.CompletePasswordResetV2Req,
  proto.org.couchers.auth.AuthRes,
  /**
   * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.AuthRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.AuthRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.AuthRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.completePasswordResetV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/CompletePasswordResetV2',
      request,
      metadata || {},
      methodDescriptor_Auth_CompletePasswordResetV2,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.AuthRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.completePasswordResetV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/CompletePasswordResetV2',
      request,
      metadata || {},
      methodDescriptor_Auth_CompletePasswordResetV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.ConfirmChangeEmailV2Req,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Auth_ConfirmChangeEmailV2 = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/ConfirmChangeEmailV2',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.ConfirmChangeEmailV2Req,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.confirmChangeEmailV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/ConfirmChangeEmailV2',
      request,
      metadata || {},
      methodDescriptor_Auth_ConfirmChangeEmailV2,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.confirmChangeEmailV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/ConfirmChangeEmailV2',
      request,
      metadata || {},
      methodDescriptor_Auth_ConfirmChangeEmailV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.ConfirmDeleteAccountReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Auth_ConfirmDeleteAccount = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/ConfirmDeleteAccount',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.ConfirmDeleteAccountReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.confirmDeleteAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/ConfirmDeleteAccount',
      request,
      metadata || {},
      methodDescriptor_Auth_ConfirmDeleteAccount,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.confirmDeleteAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/ConfirmDeleteAccount',
      request,
      metadata || {},
      methodDescriptor_Auth_ConfirmDeleteAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.RecoverAccountReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Auth_RecoverAccount = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/RecoverAccount',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.RecoverAccountReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.org.couchers.auth.RecoverAccountReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.RecoverAccountReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.recoverAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/RecoverAccount',
      request,
      metadata || {},
      methodDescriptor_Auth_RecoverAccount,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.RecoverAccountReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.recoverAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/RecoverAccount',
      request,
      metadata || {},
      methodDescriptor_Auth_RecoverAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.org.couchers.auth.UnsubscribeReq,
 *   !proto.org.couchers.auth.UnsubscribeRes>}
 */
const methodDescriptor_Auth_Unsubscribe = new grpc.web.MethodDescriptor(
  '/org.couchers.auth.Auth/Unsubscribe',
  grpc.web.MethodType.UNARY,
  proto.org.couchers.auth.UnsubscribeReq,
  proto.org.couchers.auth.UnsubscribeRes,
  /**
   * @param {!proto.org.couchers.auth.UnsubscribeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.org.couchers.auth.UnsubscribeRes.deserializeBinary
);


/**
 * @param {!proto.org.couchers.auth.UnsubscribeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.org.couchers.auth.UnsubscribeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.org.couchers.auth.UnsubscribeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.org.couchers.auth.AuthClient.prototype.unsubscribe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/org.couchers.auth.Auth/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_Auth_Unsubscribe,
      callback);
};


/**
 * @param {!proto.org.couchers.auth.UnsubscribeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.org.couchers.auth.UnsubscribeRes>}
 *     Promise that resolves to the response
 */
proto.org.couchers.auth.AuthPromiseClient.prototype.unsubscribe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/org.couchers.auth.Auth/Unsubscribe',
      request,
      metadata || {},
      methodDescriptor_Auth_Unsubscribe);
};


module.exports = proto.org.couchers.auth;

